import { useRoute } from "eddev/routing"
import { useEffect } from "react"

const COUPON_LENGTH = 1000 * 60 * 60 * 6

function enableCoupons() {
  localStorage.setItem("coupons", String(new Date().getTime()))
}

function getCouponsEnabled() {
  const duration = localStorage.getItem("coupons")
  if (!duration) {
    return false
  }
  const now = new Date().getTime()
  return now - Number(duration) < COUPON_LENGTH
}

export function CouponEnabler() {
  const route = useRoute()

  useEffect(() => {
    if (route.query["enable-coupons"]) {
      enableCoupons()
    }
  }, [route])

  return null
}

export function useCouponsEnabled() {
  return getCouponsEnabled()
}
