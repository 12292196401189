import { ComponentPropsWithoutRef, forwardRef, memo } from "react"

type SVGProps = ComponentPropsWithoutRef<"svg">

export const IconAccount = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12 11.4286C14.0513 11.4286 15.7143 9.76563 15.7143 7.71428C15.7143 5.66294 14.0513 4 12 4C9.94866 4 8.28571 5.66294 8.28571 7.71428C8.28571 9.76563 9.94866 11.4286 12 11.4286Z"
          fill="currentColor"
        ></path>
        <path d="M18.5 18.8571V13.2857H5.5V18.8571H18.5Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconAdd = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M12.5 6H11.5V11.5H6V12.5H11.5V18H12.5V12.5H18V11.5H12.5V6Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconAwards = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M4 19.1109L12 4.88867L20 19.1109H4Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconCaretDown = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M17.5 10.3462L16.6538 9.5L12 14.1538L7.34615 9.5L6.5 10.3462L12 15.8462L17.5 10.3462Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconCaretLeft = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M13.5 17.3961L14.3 16.5961L9.90005 12.1961L14.3 7.79609L13.5 6.99609L8.30005 12.1961L13.5 17.3961Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconCaretRight = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M10.5 17.3961L9.69995 16.5961L14.1 12.1961L9.69995 7.79609L10.5 6.99609L15.7 12.1961L10.5 17.3961Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconCaretUp = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M6.5 14.3999L7.34615 15.2461L12 10.5922L16.6538 15.2461L17.5 14.3999L12 8.8999L6.5 14.3999Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconClose = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M16.5962 8.11092L15.8891 7.40381L12 11.2929L8.11092 7.40381L7.40381 8.11092L11.2929 12L7.40381 15.8891L8.11092 16.5962L12 12.7071L15.8891 16.5962L16.5962 15.8891L12.7071 12L16.5962 8.11092Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconCourse = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12.4701 7.4699V19.0399C12.4701 19.0399 14.7501 16.5299 21.1501 17.6299V5.1799C14.7601 4.0899 12.4701 7.4699 12.4701 7.4699Z"
          fill="currentColor"
        ></path>
        <path
          d="M11.5401 7.4699V19.0399C11.5401 19.0399 9.25011 16.5299 2.86011 17.6299V5.1799C9.25011 4.0899 11.5401 7.4699 11.5401 7.4699Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconDirectory = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12 19C11.0317 19 10.1239 18.8184 9.27666 18.4553C8.44284 18.0922 7.70317 17.5946 7.05764 16.9625C6.4121 16.317 5.90778 15.5706 5.54467 14.7233C5.18156 13.8761 5 12.9683 5 12C5 11.0317 5.18156 10.1306 5.54467 9.29683C5.90778 8.44957 6.40538 7.70317 7.03746 7.05764C7.683 6.4121 8.4294 5.90778 9.27666 5.54467C10.1239 5.18156 11.0317 5 12 5C12.9683 5 13.8761 5.18156 14.7233 5.54467C15.5706 5.90778 16.3103 6.4121 16.9424 7.05764C17.5879 7.68972 18.0922 8.4294 18.4553 9.27666C18.8184 10.1239 19 11.0317 19 12C19 12.9683 18.8184 13.8761 18.4553 14.7233C18.0922 15.5706 17.5879 16.317 16.9424 16.9625C16.3103 17.5946 15.5706 18.0922 14.7233 18.4553C13.8761 18.8184 12.9683 19 12 19ZM11.9798 15.8127C13.3919 15.8127 14.4745 15.0932 15.2277 13.6542L14.5216 13.2709C14.2257 13.7954 13.8626 14.1921 13.4323 14.4611C13.0154 14.7166 12.5312 14.8444 11.9798 14.8444C11.415 14.8444 10.9174 14.7166 10.487 14.4611C10.0701 14.1921 9.72046 13.7954 9.43804 13.2709L8.75216 13.6542C9.50528 15.0932 10.5812 15.8127 11.9798 15.8127ZM9.33718 11.4553C9.60615 11.4553 9.82133 11.3679 9.98271 11.1931C10.1575 11.0183 10.245 10.8031 10.245 10.5476C10.245 10.292 10.1575 10.0768 9.98271 9.90202C9.82133 9.72719 9.60615 9.63977 9.33718 9.63977C9.08165 9.63977 8.86647 9.72719 8.69164 9.90202C8.51681 10.0768 8.4294 10.292 8.4294 10.5476C8.4294 10.8031 8.51681 11.0183 8.69164 11.1931C8.86647 11.3679 9.08165 11.4553 9.33718 11.4553ZM14.6628 11.4553C14.9183 11.4553 15.1335 11.3746 15.3084 11.2133C15.4832 11.0384 15.5706 10.8165 15.5706 10.5476C15.5706 10.292 15.4832 10.0768 15.3084 9.90202C15.1335 9.72719 14.9183 9.63977 14.6628 9.63977C14.4073 9.63977 14.1921 9.72719 14.0173 9.90202C13.8425 10.0768 13.755 10.292 13.755 10.5476C13.755 10.8031 13.8425 11.0183 14.0173 11.1931C14.1921 11.3679 14.4073 11.4553 14.6628 11.4553Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconError = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12 5C12.9683 5 13.8761 5.18156 14.7233 5.54467C15.5572 5.90778 16.2968 6.40538 16.9424 7.03746C17.5879 7.683 18.0922 8.4294 18.4553 9.27666C18.8184 10.1239 19 11.0317 19 12C19 12.9683 18.8184 13.8694 18.4553 14.7032C18.0922 15.5504 17.5946 16.2968 16.9625 16.9424C16.317 17.5879 15.5706 18.0922 14.7233 18.4553C13.8761 18.8184 12.9683 19 12 19C11.0317 19 10.1239 18.8184 9.27666 18.4553C8.4294 18.0922 7.68972 17.5879 7.05764 16.9424C6.41211 16.3103 5.90778 15.5706 5.54467 14.7233C5.18156 13.8761 5 12.9683 5 12C5 11.0317 5.18156 10.1239 5.54467 9.27666C5.90778 8.42939 6.41211 7.683 7.05764 7.03746C7.68972 6.40538 8.4294 5.90778 9.27666 5.54467C10.1239 5.18156 11.0317 5 12 5ZM12.0202 8.18732C10.6081 8.18732 9.52546 8.90682 8.77234 10.3458L9.47839 10.7291C9.77426 10.2046 10.1374 9.80788 10.5677 9.5389C10.9846 9.28338 11.4688 9.15562 12.0202 9.15562C12.585 9.15562 13.0826 9.28338 13.513 9.5389C13.9299 9.80788 14.2795 10.2046 14.562 10.7291L15.2478 10.3458C14.4947 8.90682 13.4188 8.18732 12.0202 8.18732ZM14.6628 12.5447C14.3939 12.5447 14.1787 12.6321 14.0173 12.8069C13.8425 12.9817 13.755 13.1969 13.755 13.4524C13.755 13.708 13.8425 13.9232 14.0173 14.098C14.1787 14.2728 14.3939 14.3602 14.6628 14.3602C14.9183 14.3602 15.1335 14.2728 15.3084 14.098C15.4832 13.9232 15.5706 13.708 15.5706 13.4524C15.5706 13.1969 15.4832 12.9817 15.3084 12.8069C15.1335 12.6321 14.9183 12.5447 14.6628 12.5447ZM9.33718 12.5447C9.08165 12.5447 8.86648 12.6254 8.69164 12.7867C8.51681 12.9616 8.4294 13.1835 8.4294 13.4524C8.4294 13.708 8.51681 13.9232 8.69164 14.098C8.86648 14.2728 9.08165 14.3602 9.33718 14.3602C9.5927 14.3602 9.80788 14.2728 9.98271 14.098C10.1575 13.9232 10.245 13.708 10.245 13.4524C10.245 13.1969 10.1575 12.9817 9.98271 12.8069C9.80788 12.6321 9.5927 12.5447 9.33718 12.5447Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconFilter = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M6 11.5V12.5H17V11.5H6Z" fill="currentColor"></path>
        <path d="M6 7V8H18V7H6Z" fill="currentColor"></path>
        <path d="M6 16V17H18V16H6Z" fill="currentColor"></path>
        <circle cx="9.5" cy="7.5" r="1.5" fill="currentColor"></circle>
        <circle cx="9.5" cy="16.5" r="1.5" fill="currentColor"></circle>
        <circle cx="16.5" cy="12" r="1.5" fill="currentColor"></circle>
      </svg>
    )
  }),
)

export const IconGenericTool = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <rect x="6" y="6" width="12" height="12" fill="currentColor"></rect>
      </svg>
    )
  }),
)

export const IconInspire = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12.767 4.41504L12.454 7.42056L15.4595 6.57526L15.6786 8.01541L12.7983 8.25021L14.6611 10.7235L13.3149 11.4592L11.9843 8.71983L10.779 11.4592L9.38582 10.7235L11.2173 8.25021L8.36832 8.01541L8.58748 6.57526L11.5617 7.42056L11.233 4.41504H12.767ZM7.89871 11.4749L7.58564 14.4804L10.5912 13.6351L10.8103 15.0753L7.93002 15.3101L9.79282 17.7834L8.44659 18.5191L7.11602 15.7797L5.91068 18.5191L4.5175 17.7834L6.34899 15.3101L3.5 15.0753L3.71915 13.6351L6.69337 14.4804L6.36464 11.4749H7.89871ZM17.5884 11.4749L17.2753 14.4804L20.2808 13.6351L20.5 15.0753L17.6197 15.3101L19.4825 17.7834L18.1363 18.5191L16.8057 15.7797L15.6004 18.5191L14.2072 17.7834L16.0387 15.3101L13.1897 15.0753L13.4088 13.6351L16.3831 14.4804L16.0543 11.4749H17.5884Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconLinkOut = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M16.1558 13.3151H17.25V6.75H10.6849V7.84418H15.3822L6.75 16.4764L7.5237 17.2501L16.1558 8.61797V13.3151Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconLock = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.14286 10.5249V8.3374C8.14286 6.16278 9.86976 4.3999 12 4.3999C14.1302 4.3999 15.8571 6.16278 15.8571 8.3374V10.5249H18V18.3999H6V10.5249H8.14286ZM9.85714 8.3374C9.85714 7.12928 10.8165 6.1499 12 6.1499C13.1835 6.1499 14.1429 7.12928 14.1429 8.3374V10.5249H9.85714V8.3374Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconMail = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M4 6.75293V17.4286H20V6.75284L12 13.61L4 6.75293Z" fill="currentColor"></path>
        <path d="M19.1222 6H4.87769L12 12.1048L19.1222 6Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconMenu = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M6 11.5V12.5H18V11.5H6Z" fill="currentColor"></path>
        <path d="M6 7V8H18V7H6Z" fill="currentColor"></path>
        <path d="M6 16V17H18V16H6Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconOnline = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M9.66667 12C9.66667 12.5911 9.69302 13.1641 9.74239 13.7111H14.2576C14.307 13.1641 14.3333 12.5911 14.3333 12C14.3333 11.4089 14.3069 10.8359 14.2573 10.2889H9.74273C9.69314 10.8359 9.66667 11.4089 9.66667 12Z"
          fill="currentColor"
        ></path>
        <path
          d="M8.80576 10.2889C8.75828 10.8406 8.73333 11.4133 8.73333 12C8.73333 12.5866 8.75816 13.1594 8.80545 13.7111H5.21064C5.07306 13.1635 5 12.5903 5 12C5 11.4097 5.07306 10.8365 5.21064 10.2889H8.80576Z"
          fill="currentColor"
        ></path>
        <path
          d="M9.85321 9.35556H14.1473C14.009 8.4137 13.7999 7.57402 13.5409 6.88435C13.2986 6.23932 13.0216 5.74959 12.7381 5.42954C12.4539 5.10869 12.2033 5 12.0003 5C11.7972 5 11.5466 5.10869 11.2624 5.42954C10.9789 5.74959 10.7019 6.23932 10.4596 6.88435C10.2006 7.57402 9.99147 8.4137 9.85321 9.35556Z"
          fill="currentColor"
        ></path>
        <path
          d="M15.1944 10.2889C15.2418 10.8406 15.2668 11.4133 15.2668 12C15.2668 12.5866 15.242 13.1594 15.1947 13.7111H18.7895C18.9271 13.1635 19.0001 12.5903 19.0001 12C19.0001 11.4097 18.9271 10.8365 18.7895 10.2889H15.1944Z"
          fill="currentColor"
        ></path>
        <path
          d="M18.4831 9.35602H15.0897C14.9436 8.30197 14.712 7.3495 14.4142 6.55664C14.2243 6.05091 14.0028 5.59771 13.7506 5.22118C15.9033 5.77549 17.6551 7.32809 18.4831 9.35602Z"
          fill="currentColor"
        ></path>
        <path
          d="M8.90993 9.35602H5.51649C6.34451 7.32809 8.09634 5.77549 10.249 5.22118C9.99686 5.59771 9.77535 6.05091 9.58538 6.55664C9.28759 7.3495 9.05606 8.30197 8.90993 9.35602Z"
          fill="currentColor"
        ></path>
        <path
          d="M8.90921 14.6444H5.51649C6.34406 16.6713 8.09448 18.2233 10.2456 18.7784C9.99356 18.4019 9.77242 17.9489 9.58289 17.4436C9.28559 16.6507 9.05478 15.6984 8.90921 14.6444Z"
          fill="currentColor"
        ></path>
        <path
          d="M13.5428 17.1159C13.8014 16.4262 14.0099 15.5865 14.1476 14.6444H9.852C9.98976 15.5865 10.1982 16.4262 10.4569 17.1159C10.6987 17.7608 10.9755 18.2504 11.2591 18.5703C11.5432 18.8908 11.7946 19 11.9998 19C12.205 19 12.4564 18.8908 12.7405 18.5703C13.0241 18.2504 13.3009 17.7608 13.5428 17.1159Z"
          fill="currentColor"
        ></path>
        <path
          d="M14.417 17.4436C14.7142 16.6507 14.9451 15.6984 15.0906 14.6444H18.4834C17.6558 16.6713 15.9054 18.2233 13.7543 18.7784C14.0063 18.4019 14.2274 17.9489 14.417 17.4436Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconPaper = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8333 5H6.5V19.6667H17.5V8.66667H13.8333V5ZM14.75 5V7.75H17.5L14.75 5Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconPhone = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M9.74974 14.2091C11.2805 15.7418 12.2102 16.5575 13.5294 17.4163C14.705 18.1816 15.6578 18.6902 16.0634 18.8281C17.236 19.2267 17.5392 18.8687 17.8973 18.4004C18.2554 17.932 18.7205 17.3083 18.8966 16.8975C19.0142 16.6236 19.1052 16.3743 18.738 15.9514C18.3707 15.5284 17.0589 14.1862 16.7025 13.8486C16.0645 13.2443 15.8797 13.53 15.5038 13.7555C15.1279 13.9809 14.4507 14.657 14.0075 14.6376C13.5462 14.6174 13.3536 14.4162 12.9655 14.11C12.5946 13.8173 11.4849 12.7048 11.3857 12.6052L11.3801 12.5996C11.2809 12.5 10.1725 11.3864 9.88214 11.0146C9.57811 10.626 9.37752 10.4326 9.36264 9.97475C9.34832 9.53482 10.0378 8.87111 10.2695 8.50084C10.5012 8.13062 10.7913 7.95068 10.1898 7.31063C9.85374 6.95315 8.5165 5.63583 8.09447 5.26659C7.67249 4.89727 7.42018 4.98469 7.14266 5.09802C6.72658 5.2679 6.09242 5.72187 5.6161 6.07158C5.13979 6.42131 4.77547 6.71782 5.1633 7.88573C5.29739 8.28974 5.79884 9.24093 6.55629 10.4162C7.40628 11.7349 8.21756 12.6668 9.74419 14.2035L9.74974 14.2091Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconPlaceholder = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <circle cx="12" cy="12" r="6" fill="currentColor"></circle>
      </svg>
    )
  }),
)

export const IconRefresh = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M13.1188 7L12.6478 11.5225L17.1702 10.2505L17.5 12.4176L13.166 12.7709L15.969 16.4925L13.9433 17.5996L11.9411 13.4775L10.1274 17.5996L8.03105 16.4925L10.7869 12.7709L6.5 12.4176L6.82976 10.2505L11.3051 11.5225L10.8105 7H13.1188Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconSave = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.625 5.25H5.25V18.75H18.75V5.25H15.375V9.75H8.625V5.25ZM12 5.25V8.625H14.25V5.25H12ZM13.6875 14.2502C13.6875 15.1821 12.932 15.9377 12 15.9377C11.068 15.9377 10.3125 15.1821 10.3125 14.2502C10.3125 13.3182 11.068 12.5627 12 12.5627C12.932 12.5627 13.6875 13.3182 13.6875 14.2502Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconSearch = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 17.4136L16.2806 14.4603C17.1373 13.3913 17.5522 12.0345 17.4399 10.6688C17.3277 9.30309 16.6968 8.03233 15.6771 7.11781C14.6574 6.20329 13.3263 5.71452 11.9575 5.75201C10.5888 5.78949 9.28636 6.35037 8.31814 7.31933C7.34992 8.28829 6.78946 9.59167 6.75201 10.9615C6.71455 12.3313 7.20295 13.6634 8.11677 14.6839C9.0306 15.7044 10.3004 16.3357 11.6651 16.448C13.0297 16.5604 14.3855 16.1452 15.4537 15.2878L18.4139 18.2497L19.25 17.4136ZM9.80289 14.5855C10.4877 15.0434 11.2928 15.2878 12.1164 15.2878C13.2208 15.2878 14.28 14.8488 15.0609 14.0672C15.8419 13.2857 16.2806 12.2257 16.2806 11.1205C16.2806 10.2962 16.0364 9.49053 15.5788 8.80521C15.1212 8.11989 14.4709 7.58575 13.71 7.27034C12.9491 6.95492 12.1118 6.87239 11.304 7.03319C10.4962 7.19399 9.75424 7.59089 9.17186 8.17371C8.58949 8.75652 8.19289 9.49907 8.03222 10.3075C7.87154 11.1158 7.954 11.9538 8.26918 12.7152C8.58436 13.4767 9.11809 14.1276 9.80289 14.5855Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconStar = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M6.32984 19.9697L8.51224 13.4837L3.25 9.95513H9.69522L12 3L14.3456 9.95513H20.75L15.4878 13.4837L17.711 19.9697L12 15.8293L6.32984 19.9697Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconSubtract = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path d="M11.5 11.5H6V12.5H11.5H12.5H18V11.5H12.5H11.5Z" fill="currentColor"></path>
      </svg>
    )
  }),
)

export const IconTools = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M12 11.3708L8.81461 8.18539L12 5L15.1854 8.18539L12 11.3708ZM16.0646 15.4354L12.8792 12.25L16.0646 9.06461L19.25 12.25L16.0646 15.4354ZM7.93539 15.4354L4.75 12.25L7.93539 9.06461L11.1208 12.25L7.93539 15.4354ZM12 19.5L8.81461 16.3146L12 13.1292L15.1854 16.3146L12 19.5Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)

export const IconUnlock = memo(
  forwardRef<SVGSVGElement, SVGProps>((props, ref) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" {...props} ref={ref}>
        <path
          d="M8.14286 8.3374V10.5249H6V18.3999H18V10.5249H9.85714V8.3374C9.85714 7.12928 10.8165 6.1499 12 6.1499C13.0367 6.1499 13.9014 6.90142 14.1 7.8999H15.8336C15.6204 5.93117 13.9854 4.3999 12 4.3999C9.86976 4.3999 8.14286 6.16278 8.14286 8.3374Z"
          fill="currentColor"
        ></path>
      </svg>
    )
  }),
)
