import { useIsLoggedIn } from "@features/accounts/hooks/useAuth"
import { useAppData } from "eddev/hooks"
import { RouteState, useRoute } from "eddev/routing"
import { ReactNode, useMemo } from "react"
import { parseURL, withTrailingSlash } from "ufo"
import { MenuLocationEnum } from "../types.graphql"
import { useAccountMenu } from "./useAccountMenu"

export type TopNav = (
  | {
      mode: "title"
      title: string
      href?: string
    }
  | {
      mode: "render"
      icon: ReactNode
    }
  | {
      mode: "buttons"
      key?: string
      links: { label: string; href: string }[]
    }
  | {
      mode: "sublinks"
      key?: string
      top: { label: string; href?: string }
      links: { label: string; href: string }[]
    }
) & {
  mobileButton?: {
    label?: string
    href?: string
  }
}

function isParentOrSame(a: string, b: string) {
  const linkA = parseURL(a)
  const linkB = parseURL(b)
  const pathA = withTrailingSlash(linkA.pathname)
  const pathB = withTrailingSlash(linkB.pathname)
  if (pathA === "/" || pathB === "/") return false
  if (pathA === pathB) {
    return "same"
  } else if (pathB.startsWith(pathA)) {
    return "parent"
  }
}

function getTypeParentUri(route: RouteState) {
  if (route.view === "single-article" || route.view === "single-article-editorial") {
    return "/discover"
  } else if (route.view === "single-event") {
    return "/events"
  } else if (route.view === "single-resource") {
    return "/resources"
  } else if (route.view === "single-profile") {
    return "/directory"
  } else if (route.view === "single-award") {
    return "/awards"
  } else if (route.view === "template-faq-listing" && route.props.faq?.slug) {
    return "/faqs"
  }
}

export function useTopNav(): TopNav | null {
  const loggedIn = useIsLoggedIn()
  const route = useRoute()
  const menus = useAppData((data) => data.menus)
  const accountMenu = useAccountMenu(true)

  const parentUri = getTypeParentUri(route)

  const activeMenu = useMemo(() => {
    if (route.pathname === "/") return menus?.nodes.find((n) => n.locations?.includes(MenuLocationEnum.Discover))
    return menus?.nodes.find((menu) => {
      return menu.menuItems?.nodes.some((node) => {
        return !!isParentOrSame(node.url ?? "/", parentUri ?? route.pathname) || !!isParentOrSame(node.url ?? "/", route.pathname)
      })
    })
  }, [menus, route])

  const allowFullMenu = activeMenu?.locations?.includes(MenuLocationEnum.Discover)

  return useMemo(() => {
    const menuItem = activeMenu?.menuItems?.nodes.find((item) => !!isParentOrSame(item.url ?? "/", parentUri ?? route.pathname))

    const mobileButton = menuItem ? { label: menuItem.label!, href: menuItem.url! } : undefined

    if (menuItem && menuItem.childItems?.nodes.length! > 0) {
      return {
        mode: "sublinks",
        key: menuItem.url!,
        top: {
          label: menuItem.label!,
          href: menuItem.url!,
        },
        links: menuItem.childItems?.nodes?.map((item) => ({ label: item.label!, href: item.url! })) ?? [],
        mobileButton,
      }
    }

    if (!allowFullMenu && menuItem) {
      return {
        mode: "title",
        title: menuItem.label!,
        href: menuItem.url!,
        mobileButton,
      }
    }

    if (route.pathname.startsWith("/account") || route.pathname.startsWith("/dashboard")) {
      if (!loggedIn) return null
      return {
        mode: "sublinks",
        key: "account",
        top: {
          label: "Account",
          href: "/dashboard",
        },
        links: accountMenu.filter((b) => !b.action) as { label: string; href: string }[],
        mobileButton,
      }
    }

    if (activeMenu && (isParentOrSame(menuItem?.url ?? "/", route.pathname) || route.pathname === "/")) {
      return {
        mode: "buttons",
        key: activeMenu.slug!,
        links: activeMenu.menuItems?.nodes.map((item) => ({
          href: item.url!,
          label: item.label!,
        }))!,
        mobileButton,
      }
    }

    // Fallback
    if (menuItem) {
      return {
        mode: "title",
        title: menuItem?.label!,
        href: menuItem?.url!,
        mobileButton,
      }
    }
    return null
  }, [activeMenu, accountMenu, loggedIn, menus, route])
}
