import { SidebarLayout } from "@components/page/SidebarLayout"
import { Link } from "eddev/routing"
import { defineView } from "eddev/views"

export default defineView("_error", () => {
  return (
    <div className="absolute left-0 top-0 flex justify-center items-center w-full h-[80vh]">
      <div className="grid-auto flex flex-col justify-start items-start type-body-s-roman md:w-[390px]">
        <h1 className="type-title-l-medium">Page not found.</h1>
        <div className="w-full h-[1px] border-t border-black my-3" />
        <p>We couldn't find the page you were looking for.</p>
        <p>Perhaps explore our <Link href="/directory">Directory</Link> or <Link href="/faq">FAQs</Link></p>
      </div>
    </div>
  )
})
