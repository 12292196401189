import { useIsPresent } from "framer-motion"
import { createContext, useContext, useLayoutEffect } from "react"
import { create } from "zustand"

const RouteIndexContext = createContext(0)

export const RouteIndexProvider = RouteIndexContext.Provider

export const useRouteIndex = () => {
  const context = useContext(RouteIndexContext)
  return context ?? -1
}

const useRoutePriorityStore = create<{
  entries: Record<string, number[]>
  mount: (id: string, index: number) => () => void
}>((set, get) => ({
  entries: {},
  mount: (id, index) => {
    set({
      entries: {
        ...get().entries,
        [id]: [...(get().entries[id] || []), index].sort(),
      },
    })
    return () => {
      set((state) => {
        const idx = state.entries[id].indexOf(index)
        return {
          entries: {
            ...state.entries,
            [id]: state.entries[id].filter((_, i) => i !== idx).sort(),
          },
        }
      })
    }
  },
}))

export function useRoutePriority(id: string) {
  const index = useRouteIndex()
  const present = useIsPresent()

  const mount = useRoutePriorityStore((state) => state.mount)

  if (env.client) {
    useLayoutEffect(() => {
      return mount(id, index)
    }, [index, id])
  }

  const isFront = useRoutePriorityStore((state) => {
    const entries = state.entries[id] || []
    // if (!entries.includes(index)) {
    //   entries.push(index)
    //   entries.sort()
    // }
    const stackIndex = entries.indexOf(index)
    if (!present && entries.length > 1) {
      return false
    }
    return stackIndex === entries.length - 1
  })

  return isFront
}
