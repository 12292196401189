import { isValidElement, ReactNode } from "react"

export function getErrorMessage(error: any): ReactNode {
  if (isValidElement(error)) {
    return error
  }
  return getErrorMessageText(error)
}

export function getErrorMessageText(error: any): string {
  if (typeof error === "string") {
    return error
  } else if (Array.isArray(error)) {
    return error.map(getErrorMessage).join(". ")
  }
  if (error instanceof Error) {
    return error.message
  } else if (error && typeof error === "object") {
    return getErrorMessageText(error.messages || error.message || error.error || error.msg || error.detail || JSON.stringify(error))
  } else {
    return JSON.stringify(error)
  }
}
