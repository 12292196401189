import { ComponentProps, memo } from "react"

export const AGDALogo = memo((props: ComponentProps<"svg">) => {
  return (
    <svg width="77" height="29" viewBox="0 0 77 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M26.3849 17.5779H32.9486V17.7302C32.9486 20.7427 30.1633 23.9076 25.9264 23.9076C21.2322 23.9076 18.2945 19.8278 18.2945 14.2608C18.2945 8.69377 20.8512 4.57592 26.0038 4.57592C29.5143 4.57592 31.5375 6.48195 32.1484 9.11376H37.5678C36.689 3.77519 32.7581 0 25.8895 0C22.2254 0 19.3257 1.10531 17.112 3.12676C14.2493 5.75737 12.6465 9.72293 12.6465 14.2608C12.6465 18.3786 13.9445 21.8861 16.1951 24.4025C18.4469 26.8808 21.6526 28.3681 25.774 28.3681C29.0178 28.3681 31.5744 27.2628 33.444 24.136H33.5202L33.7107 27.7958H37.8702V13.3066H26.3837V17.5767L26.3849 17.5779Z" />
      <path d="M52.7916 1.10742H42.1445V28.369H52.3343C55.7686 28.369 58.4015 27.416 60.4247 25.7384C63.2874 23.3362 64.7747 19.4468 64.7747 15.0243C64.7747 6.97902 60.0805 1.10742 52.7916 1.10742ZM51.952 23.6027H47.6782V5.83563H52.1045C56.7986 5.83563 59.1648 9.61082 59.1648 15.0243C59.1648 20.4379 57.1416 23.6027 51.952 23.6027Z" />
      <path d="M0 1.10742V6.29251C0 6.29251 0.648992 9.03735 1.10626 10.4865L3.54862 18.3415H0V22.5735H4.8847L6.71617 28.369H12.4785L2.86271 1.10742H0Z" />
      <path d="M77.0001 6.29251V1.10742H74.2136L64.7109 28.369H70.3208L72.1142 22.5735H76.9989V18.3415H73.411L75.8533 10.4865C76.3118 9.03735 76.9977 6.29251 76.9977 6.29251H77.0001Z" />
    </svg>
  )
})
