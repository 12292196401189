import { Button } from "@components/controls/Button"
import { IconError, IconLock } from "@components/svg/Icons"
import { AlertContent } from "./AlertContent"
import { Spinner } from "@components/atoms/Spinner"
import { BackButton, Link, RouteState, useRoute, useRouter, useRouterState } from "eddev/routing"
import { MakeURL } from "@utils/create-url"
import { FunctionComponent, ReactNode, useMemo } from "react"
import { getErrorMessage } from "@utils/errors"

export function NoAccess(props: { backUrl?: string }) {
  const router = useRouter()
  const route = useRoute()

  return (
    <div className="w-full h-pageMin flex items-center justify-center">
      <AlertContent
        title="Members only!"
        icon={<IconLock />}
        buttons={
          <>
            <BackButton
              // fallbackHref={props.backUrl}
              render={({ onClick }) => (
                <Button variant="outline" onClick={onClick}>
                  Go Back
                </Button>
              )}
            />
            <Button href={MakeURL.loginFirst(route.uri)} variant="outline">
              Login
            </Button>
            <Button href={MakeURL.join()} variant="charcoal">
              Become a Member
            </Button>
          </>
        }
        message={
          <p>
            This is for AGDA members only. Register to access, or to find out more, please read about our <Link href="/membership">Member Benefits</Link>.
          </p>
        }
      />
    </div>
  )
}

export function CheckingAccess() {
  return (
    <div className="w-full h-pageMin flex items-center justify-center">
      <AlertContent title="Verifying access" icon={<Spinner />} />
    </div>
  )
}

type ErrorPanelProps = {
  title: string
  message?: any
  backUrl?: string
  button?: ReactNode
}

export function ErrorPanel(props: ErrorPanelProps) {
  return (
    <div className="w-full h-pageMin flex items-center justify-center">
      <AlertContent
        title={props.title ?? "Error"}
        icon={<IconError />}
        buttons={
          <>
            <BackButton
              fallbackHref={props.backUrl}
              render={({ onClick }) => (
                <Button variant="outline" onClick={onClick}>
                  Go Back
                </Button>
              )}
            />
            {props.button}
          </>
        }
        message={getErrorMessage(props.message)}
      />
    </div>
  )
}
