import _app from "../../../views/_app.tsx";
import _error from "../../../views/_error.tsx";
import _html from "../../../views/_html.tsx";

import { viewManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
const manifest = {
  "_app": _app,
  "_error": _error,
  "_html": _html,
  "account/account-dashboard": dynamic(() => import("../../../views/account/account-dashboard.tsx")),
  "account/account-details": dynamic(() => import("../../../views/account/account-details.tsx")),
  "account/account-events": dynamic(() => import("../../../views/account/account-events.tsx")),
  "account/account-profile": dynamic(() => import("../../../views/account/account-profile.tsx")),
  "archive-classified": dynamic(() => import("../../../views/archive-classified.tsx")),
  "archive-event": dynamic(() => import("../../../views/archive-event.tsx")),
  "archive-hall-of-fame": dynamic(() => import("../../../views/archive-hall-of-fame.tsx")),
  "archive-judge": dynamic(() => import("../../../views/archive-judge.tsx")),
  "checkout/checkout": dynamic(() => import("../../../views/checkout/checkout.tsx")),
  "checkout/order": dynamic(() => import("../../../views/checkout/order.tsx")),
  "front-page": dynamic(() => import("../../../views/front-page.tsx")),
  "login": dynamic(() => import("../../../views/login.tsx")),
  "membership/join": dynamic(() => import("../../../views/membership/join.tsx")),
  "page": dynamic(() => import("../../../views/page.tsx")),
  "search": dynamic(() => import("../../../views/search.tsx")),
  "single": dynamic(() => import("../../../views/single.tsx")),
  "single-article": dynamic(() => import("../../../views/single-article.tsx")),
  "single-article-editorial": dynamic(() => import("../../../views/single-article-editorial.tsx")),
  "single-award": dynamic(() => import("../../../views/single-award.tsx")),
  "single-event": dynamic(() => import("../../../views/single-event.tsx")),
  "single-hall-of-fame": dynamic(() => import("../../../views/single-hall-of-fame.tsx")),
  "single-hall-of-fame-editorial": dynamic(() => import("../../../views/single-hall-of-fame-editorial.tsx")),
  "single-profile": dynamic(() => import("../../../views/single-profile.tsx")),
  "single-resource": dynamic(() => import("../../../views/single-resource.tsx")),
  "template-awards-listing": dynamic(() => import("../../../views/template-awards-listing.tsx")),
  "template-discover": dynamic(() => import("../../../views/template-discover.tsx")),
  "template-faq-listing": dynamic(() => import("../../../views/template-faq-listing.tsx")),
  "template-find-a-designer": dynamic(() => import("../../../views/template-find-a-designer.tsx")),
  "template-hall-of-fame": dynamic(() => import("../../../views/template-hall-of-fame.tsx")),
  "template-resource-listing": dynamic(() => import("../../../views/template-resource-listing.tsx")),
  "template-styles": dynamic(() => import("../../../views/template-styles.tsx")),
};

viewManifestReader.value = manifest;

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}
