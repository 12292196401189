import { useAuth } from "@features/accounts/hooks/useAuth"
import { MakeURL } from "@utils/create-url"
import { useRoute } from "eddev/routing"
import { useMemo } from "react"

type Item = {
  label: string
  action?: boolean
  onClick?: () => void
  href?: string
}

export function useAccountMenu(forced?: boolean): Item[] {
  const route = useRoute()
  const auth = useAuth()

  return useMemo(() => {
    if (auth.isLoggedIn() || forced) {
      return [
        {
          label: "Overview",
          href: "/dashboard",
        },
        {
          label: "Account Details",
          href: "/account",
        },
        {
          label: "Events",
          href: "/account/events",
        },
        {
          label: "My Profile",
          href: "/account/profile",
        },
        // {
        //   label: "My Content",
        //   href: "/account/content",
        // },
        // {
        //   label: "Awards",
        //   href: "/account/awards",
        // },
        {
          label: "Sign Out",
          action: true,
          onClick: () => {
            auth.logout()
          },
        },
      ]
    } else {
      return [
        {
          label: "Sign In",
          href: MakeURL.loginFirst(route.uri),
        },
        {
          label: "Become a Member",
          href: "/join",
        },
      ]
    }
  }, [auth.isLoggedIn(), forced, route])
}
