import { Button, ButtonVariantType } from "@components/controls/Button"
import { useAuth, useIsLoggedIn } from "@features/accounts/hooks/useAuth"
import { MakeURL } from "@utils/create-url"
import { useIsSSR, useRoute } from "eddev/routing"
import { motion } from "framer-motion"
import { ComponentProps } from "react"
import { twMerge } from "tailwind-merge"

type Props = ComponentProps<typeof motion.div> & { buttonVariant?: ButtonVariantType }

/**
 * Displays either 'Sign Out' or 'Sign In' + 'Register'
 */
export function AuthButtons({ buttonVariant = "outline", ...props }: Props) {
  const auth = useAuth()
  const loggedIn = useIsLoggedIn()
  const route = useRoute()

  return (
    <motion.div
      {...props}
      className={twMerge("flex items-center gap-2", props.className)}
      variants={{
        visible: {
          opacity: 1,
          transition: {
            duration: 0.6,
          },
        },
        hidden: {
          opacity: 0,
        },
      }}
    >
      {loggedIn ? (
        <>
          <Button variant={buttonVariant} onClick={() => auth.logout()}>
            Sign out
          </Button>
        </>
      ) : (
        <>
          <Button href={MakeURL.loginFirst(route.uri)} variant={buttonVariant}>
            Sign In
          </Button>
          <Button href={MakeURL.join()} variant={buttonVariant}>
            Register
          </Button>
        </>
      )}
    </motion.div>
  )
}
