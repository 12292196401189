import { IconAwards } from "@components/svg/Icons"
import { ReactNode, useMemo } from "react"
import { twMerge } from "tailwind-merge"

type Props = {
  className?: string
}

function createSpinner() {
  const path = []
  const RAD = 12
  const SIZE = 24
  for (let i = 0; i < 3; i++) {
    path.push([Math.sin, Math.cos].map((func) => Number(func((i / 3) * Math.PI * 2) * RAD + SIZE / 2).toPrecision(4)))
  }
  return (
    <svg width={SIZE} height={SIZE} viewBox={`0 0 ${SIZE} ${SIZE}`} className="animate-spinner">
      <path d={"M" + path.join("L")} fill="currentColor" />
    </svg>
  )
}

export function Spinner(props: Props) {
  const svg = useMemo(createSpinner, [])

  return <span className={twMerge("items-center justify-center el-svg:size-[1em]", props.className)}>{svg}</span>
}
