import { Button } from "@components/controls/Button"
import { AGDALogo } from "@components/svg/AGDALogo"
import { Link } from "eddev/routing"
import { AuthButtons } from "./AuthButtons"
import { useEffect, useLayoutEffect, useState } from "react"
import { motion } from "framer-motion"
import { Menu } from "@components/atoms/Menu"

export function Footer() {
  return (
    <motion.footer exit={{ opacity: 0 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="subtheme-dark bg-bg text-fg py-5 flex flex-col gap-8 md:gap-9">
      {/* Logo & Login/Logout shortcuts */}
      <div className="grid-auto">
        <div className="col-span-12 md:col-span-6 pb-7 md:pb-0">
          <AGDALogo className="w-full h-auto fill-fg" />
        </div>
        <div className="col-span-12 md:col-span-6">
          <AuthButtons buttonVariant="reversed" />
        </div>
      </div>

      {/* Subscribe */}
      {/* <div className="grid-container">
        <NewsletterForm />
      </div> */}

      {/* Menu */}
      <div className="grid-auto type-body-m-roman">
        <div className="col-span-6 md:col-span-2 mt-4">
          <Menu location="Discover" maxDepth={0} className="[&>li]:mb-3" />
        </div>
        <div className="col-span-6 md:col-span-2 mt-4">
          <Menu location="Corporate" maxDepth={0} className="[&>li]:mb-3" />
        </div>
        <div className="col-span-6 md:col-span-2 mt-4">
          <Menu location="Social" maxDepth={0} className="[&>li]:mb-3" />
        </div>
        <div className="col-span-6 md:col-span-2 mt-4">
          <Menu location="Legal" maxDepth={0} className="[&>li]:mb-3" />
        </div>
      </div>

      {/* More stuff */}
      <div className="grid-auto">
        <div className="col-span-12 md:col-span-7 text-pretty type-body-m-roman text-grey flex flex-col el-p:mt-[0.6em]">
          <p>
            AGDA acknowledges the First Peoples of Australia, their Elders past, present and emerging. We pay our respects to the traditional storytellers, designers, artists and
            owners of the land on which we live and work.
          </p>
          <p>&copy; {new Date().getFullYear()} Australian Graphic Design Association.</p>
        </div>
        <div className="col-span-12 md:col-span-5 text-right mt-5 md:mt-0 type-body-m-roman flex md:items-end md:justify-end">
          <a href="https://ed.studio/" target="_blank" className="text-grey hover:text-grey-light transition-colors">
            Site by ED.
          </a>
        </div>
      </div>
    </motion.footer>
  )
}

function NewsletterForm() {
  const [submitting, setSubmitting] = useState(false)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        setSubmitting(!submitting)
      }}
      className="stack-y-2"
    >
      <div className="type-ui-s-roman-caps text-grey">Sign up for industry news, events, and insights</div>
      <input className="bg-transparent border-fg border-b type-title-l-medium pb-3 antialiased outline-none placeholder:text-white/40" placeholder="you@internet.web" />
      <div className="mt-4">
        <Button type="submit" variant="reversed" loading={submitting}>
          Subscribe
        </Button>
      </div>
    </form>
  )
}
