import { ComponentProps, forwardRef, useEffect, useLayoutEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"
import { tv } from "tailwind-variants"

type Props = ComponentProps<"input"> & {
  large?: boolean
  error?: string
}

const inputStyles = tv({
  base: [
    "flex items-center transition-colors duration-200 px-2 py-0 type-ui-m-light h-button",
    "field-stretch",
    "bg-white text-black border-b border-solid border-black",
    "hover:bg-grey-light",
    "placeholder:text-black/50 autofill:bg-[#FFF6DF]",
    "error:text-error-dark error:placeholder:text-error-dark error:bg-error-light error:hover:bg-grey-light error:border-error-dark",
    "disabled:border-grey-light disabled:text-grey-light disabled:bg-white disabled:placeholder:text-grey-light",
  ],
  variants: {
    large: {
      true: "p-3 h-buttonLg resize-none",
    },
    dynamic: {
      true: "min-h-[var(--size-button)] py-2 resize-none",
    },
    error: {
      true: "error",
    },
  },
})

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { large, className, error, ...additionalProps } = props
  const Comp = (large ? "textarea" : "input") as any

  return <Comp ref={ref} {...additionalProps} className={twMerge(inputStyles({ error: !!error, large: large, className }))} />
})

export function DynamicTextArea(props: ComponentProps<"textarea">) {
  const styles = inputStyles({ dynamic: true })
  const sensor = useRef<HTMLDivElement>(null!)
  const textarea = useRef<HTMLTextAreaElement>(null!)

  useLayoutEffect(() => {
    const el = sensor.current
    const update = () => {
      const height = el.getBoundingClientRect().height
      textarea.current.style.setProperty("--height", `${height}px`)
    }
    update()
    const ro = new ResizeObserver(update)
    ro.observe(el)
    return () => ro.disconnect()
  }, [props.value, props.placeholder])

  return (
    <div className="relative">
      <div className={twMerge(styles, "absolute top-0 left-0 right-0 !h-auto opacity-0 -z-10 pointer-events-none select-none whitespace-pre-wrap")} ref={sensor}>
        {String(props.value || props.placeholder) + "."}
      </div>
      <textarea {...props} className={twMerge(styles, props.className, "!h-[var(--height)]")} ref={textarea} />
    </div>
  )
}

TextInput.displayName = "TextInput"
