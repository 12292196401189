import { useEffect, useMemo, useRef, useState } from "react"

/**
 * Lags a value behind by a specified delay.
 */
export function useDelayedValue<T>(value: T, delay: number): T {
  const [state, setState] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(value)
    }, delay)
    return () => clearTimeout(timer)
  }, [value, delay])

  return state
}

/**
 * Essentially useMemo(), but provides the old value to the compute function.
 *
 * Return the old value if it still makes sense.
 */
export function useReduceMemo<TOutput>(compute: (last?: NoInfer<TOutput>) => TOutput, dep: any[]): TOutput {
  const ref = useRef<TOutput>()

  useMemo(() => {
    ref.current = compute(ref.current)
  }, [...dep])

  return ref.current as TOutput
}

export function useAnimKey(value: string) {
  return useReduceMemo<string>((prev) => Math.floor(Math.random() * 100000000).toString(26), [value])
}
