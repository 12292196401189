import { ToolbarButton } from "@components/controls/Button"
import { TopNav } from "@components/navigation/TopNav"
import { AGDALogo } from "@components/svg/AGDALogo"
import { IconAccount, IconClose, IconMenu } from "@components/svg/Icons"
import { useHeader } from "@hooks/useHeader"
import clsx from "clsx"
import { Link } from "eddev/routing"
import { LayoutGroup, motion } from "framer-motion"
import { useRef } from "react"

export function Header() {
  return (
    <>
      <LayoutGroup id="topNav">
        <motion.header className="fixed inset-0 bottom-auto h-headerHeight z-[600]" layoutRoot layoutScroll={false}>
          <div className="grid w-grid grid-cols-[1fr_auto_1fr] h-full mx-auto items-center">
            {/* Logo */}
            <div className="el-a:inline-flex el-svg:fill-current flex items-center">
              <Link href="/">
                <AGDALogo />
              </Link>
            </div>
            {/* Center */}
            <div>
              <TopNav />
            </div>
            {/* Buttons */}
            <div className="flex items-center justify-end gap-3">
              <AccountButton />
              <MainMenuButton />
            </div>
          </div>
        </motion.header>
        {/* Glassy overlay */}
        <HeaderGlass />
      </LayoutGroup>
    </>
  )
}

export function AccountButton() {
  const header = useHeader()
  const isOpen = header.accountMenuOpen

  return (
    <Link
      href="/account"
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey) return
        e.preventDefault()
        if (isOpen) {
          header.closeAccountMenu()
        } else {
          header.openUserMenu()
        }
      }}
    >
      <ToolbarButton variant="glass" size="lg" active={!!isOpen}>
        <IconAccount />
      </ToolbarButton>
    </Link>
  )
}

export function MainMenuButton() {
  const menu = useHeader()
  const isOpen = menu.mainMenuOpen

  return (
    <ToolbarButton
      variant="glass"
      size="lg"
      onClick={() => {
        if (isOpen) {
          menu.close()
        } else {
          menu.openMainMenu()
        }
      }}
    >
      {!isOpen ? <IconMenu /> : <IconClose />}
    </ToolbarButton>
  )
}

function HeaderGlass() {
  const ref = useRef<HTMLDivElement>(null!)
  const open = useHeader((h) => h.mainMenuOpen)

  return (
    <div
      ref={ref}
      className={clsx("fixed z-[398] pointer-events-none backdrop-blur bg-glass/80 -top-[2px] left-0 right-0 h-headerHeight transition-opacity", open && "opacity-100")}
    />
  )
}
