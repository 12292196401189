import { Footer } from "@components/site/Footer"
import { Header } from "@components/site/Header"
import { MenuOverlay } from "@components/site/MenuOverlay"
import { SentryTracing } from "@components/tracing/Sentry"
import { AuthManager } from "@features/accounts/components/AuthManager"
import { CouponEnabler } from "@features/events/components/CouponEnabler"
import { AlertManager } from "@hooks/useAlertOverlay"
import { RouteIndexProvider } from "@hooks/useRouteIndex"
import { RouteDisplay, RouteState, useRouterState } from "eddev/routing"
import { defineView } from "eddev/views"
import { AnimatePresence, motion, useIsPresent } from "framer-motion"
import { PropsWithChildren, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Flipper } from "react-flip-toolkit"
import { twMerge } from "tailwind-merge"

export default defineView("_app", (props) => {
  return (
    <>
      <SentryTracing />
      {/* <Background /> */}
      <CouponEnabler />
      <AuthManager />
      <AlertManager />
      <MenuOverlay />
      <Header />

      {/* Page content */}
      <RouteStack />
    </>
  )
})

const DIRECTORY_VIEWS: (keyof ViewProps)[] = [
  "front-page",
  "archive-classified",
  "archive-hall-of-fame",
  "archive-event",
  "template-resource-listing",
  "template-hall-of-fame",
  "template-find-a-designer",
  "template-discover",
  "template-awards-listing",
]

const SINGLE_VIEWS: (keyof ViewProps)[] = [
  "single",
  "single-article",
  "single-article-editorial",
  "single-hall-of-fame",
  "single-hall-of-fame-editorial",
  "single-event",
  "single-profile",
  "single-resource",
  "single-award",
]

class PageTransitionManager {
  stack: RouteState[] = []

  setStack(stack: RouteState[]) {
    const currentDiff = this.stack.map((r1) => (stack.some((r2) => r1.id === r2.id) ? "keep" : "remove"))
    const newDiff = stack.map((r1) => (this.stack.some((r2) => r1.id === r2.id) ? "keep" : "add"))

    let mode: "push" | "pop" | "swap" | "replace" = "swap"

    if (currentDiff.every((d) => d === "keep") && newDiff.at(-1) === "add") {
      mode = "push"
    } else if (currentDiff.at(-1) === "remove" && newDiff.every((d) => d === "keep")) {
      mode = "pop"
    } else if (currentDiff.every((d) => d === "remove") && newDiff.every((d) => d === "add")) {
      mode = "swap"
      if (this.stack.at(-1)?.key === stack.at(-1)?.key) {
        mode = "replace"
      }
    }

    this.stack = stack

    if (mode === "swap") {
      return [stack.filter((r, i) => newDiff[i] != "add"), stack]
    } else {
      return [stack]
    }
  }
}

function RouteStack() {
  const routerState = useRouterState()

  const [routes, setRoutes] = useState(() => [routerState.activeRoute])

  const manager = useMemo(() => {
    return new PageTransitionManager()
  }, [])

  const activeRoute = routes[routes.length - 1]

  useEffect(() => {
    const out = []
    for (let item of [...routerState.history].reverse()) {
      const isDirectory = DIRECTORY_VIEWS.includes(item.view)
      const isSingle = SINGLE_VIEWS.includes(item.view)
      if (out.length === 0 || isDirectory) {
        out.push(item)
        if (!isSingle) {
          break
        }
      }
      if (!isSingle) break
    }
    out.reverse()
    const result = manager.setStack(out)
    setRoutes(result[0])
    if (result[1]) {
      const timer = setTimeout(() => {
        setRoutes(result[1])
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [routerState.history])

  const [flipKey, setFlipKey] = useState("")
  if (env.client) {
    useLayoutEffect(() => {
      document.scrollingElement!.scrollTop = activeRoute?.returnState?.scrollTop ?? 0
      setFlipKey(routes.map((r) => r.id).join(","))
    }, [activeRoute])
  }

  return (
    <Flipper flipKey={flipKey}>
      <div id="routes">
        <AnimatePresence initial={false}>
          {routes.map((route, i) => {
            const active = route === activeRoute
            return (
              <motion.div key={route.key}>
                <PageWrapper route={route} active={active} distance={routes.length - i} index={i}>
                  <div className="pt-headerHeight min-h-[80vh]">
                    <RouteDisplay route={route} />
                  </div>
                  {route.view !== "front-page" && active && <Footer key="footer" />}
                </PageWrapper>
              </motion.div>
            )
          })}
        </AnimatePresence>
      </div>
    </Flipper>
  )
}

function PageWrapper(props: PropsWithChildren<{ route: RouteState; active: boolean; distance: number; index: number }>) {
  const isPresent = useIsPresent()
  const { route, distance } = props

  useEffect(() => {
    // if (!isPresent) {
    //   const timer = setTimeout(() => {
    //     safeToRemove()
    //   }, 500)
    //   return () => clearTimeout(timer)
    // }
  }, [isPresent])

  const active = props.active

  return (
    <motion.div
      variants={{
        faded: {
          z: 0.5,
          opacity: 1,
        },
        show: {
          z: 1,
          opacity: 1,
        },
        hide: {
          z: 0,
          opacity: 1,
        },
      }}
      // data-route-key={route.key}
      // data-state={[isPresent ? "present" : "absent", active ? "active" : "inactive"].join(" ")}
      initial="hide"
      animate={active ? "show" : "faded"}
      exit="hide"
      className={twMerge(!active || !isPresent ? "fixed inset-0 overflow-clip" : "absolute top-0 left-0 right-0")}
    >
      {/* Background gradient bit */}
      <motion.div
        variants={{
          faded: {
            opacity: 1,
          },
          show: {
            opacity: 1,
            transition: {
              delay: 0.2,
              duration: 0.2,
            },
          },
          hide: {
            opacity: 0,
            transition: {
              delay: 0.1 * distance,
              duration: 0.1,
            },
          },
        }}
        className={"absolute left-0 top-0 right-0 h-[200px] z-[49] pointer-events-none"}
        style={{
          background: "linear-gradient(180deg, rgb(var(--color-glass) / 1) 90%, rgb(var(--color-glass) / 0) 100%)",
        }}
        initial="hide"
        animate={!active ? "show" : "hide"}
        exit="hide"
        data-route-blur
      ></motion.div>
      {/* Blur overlay, which sits on top of a fixed page */}
      <motion.div
        variants={{
          faded: {
            opacity: 1,
            backdropFilter: "blur(40px)",
          },
          show: {
            opacity: 1,
            backdropFilter: "blur(40px)",
            transition: {
              delay: 0,
              duration: 0.3,
            },
          },
          hide: {
            opacity: 0,
            backdropFilter: "blur(20px)",
            transition: {
              delay: 0.1 * distance,
              duration: 0.2,
            },
          },
        }}
        className={"bg-glass/70 absolute inset-0 z-[50] pointer-events-none"}
        initial="hide"
        animate={!active ? "show" : "hide"}
        exit="hide"
        data-route-blur
      ></motion.div>
      <motion.div
        key={route.key}
        initial="hide"
        animate="show"
        exit="hide"
        style={!active || !isPresent ? { position: "relative", top: -(route.returnState?.scrollTop ?? 0) } : {}}
        data-route-contain
        onFocusCapture={(e) => {
          if (!active) {
            e.preventDefault()
            e.stopPropagation()
            e.target.blur()
          }
        }}
      >
        <RouteIndexProvider value={isPresent ? props.index : -1}>{props.children}</RouteIndexProvider>
      </motion.div>
    </motion.div>
  )
}
