import { useRefreshAuthToken } from "@hooks/queries"
import { apiConfig } from "eddev/runtime"
import { DEFAULT_AUTH_TOKEN_TIMEOUT, useAuth } from "../hooks/useAuth"

type Props = {}

export function AuthManager(props: Props) {
  return null
}

function getQueryKey() {
  return useAuth.getState().session?.sessionToken + "+" + useAuth.getState().user?.authToken
}

function doesQueryRequiresToken(query: string) {
  // These queries shouldn't use the auth token, because they're used to get the auth token!
  if (/(UseLogin|UseRefreshAuthToken)/.test(query)) {
    return false
  }
  // These queries should use the auth token, because they're user-related
  if (/(cart|users)/.test(query)) {
    return true
  }
  // Default to false
  return false
}

function init() {
  let checkingTokens = checkRefresh()

  useAuth.setState({
    authPromise: checkingTokens,
  })

  checkingTokens.then(() => {
    useAuth.setState({
      authPromise: null,
    })
  })

  apiConfig.set({
    customQueryKey: getQueryKey(),
    customQueryFetchOptions: async (type, query, args, url, opts) => {
      // Ignore for login requests
      if (!doesQueryRequiresToken(query)) return opts

      // Ensure we've checked the tokens before allowing further requests
      await checkingTokens

      // Add the custom headers if needed
      const headers = { ...opts.headers } as Record<string, string>
      const authState = useAuth.getState()
      if (authState.user?.authToken) {
        headers["Authorization"] = "Bearer " + authState.user.authToken
      }
      // console.log("Calling with session token", authState.session?.sessionToken)
      if (authState.session?.sessionToken) {
        headers["woocommerce-session"] = "Session " + authState.session.sessionToken
      }
      opts.credentials = "omit"

      return {
        ...opts,
        headers: {
          ...opts.headers,
          ...headers,
        },
      }
    },
    onResponse(response, type, queryName) {
      if (!doesQueryRequiresToken(queryName)) return
      // console.log("GOT SESSION", response.headers.get("woocommerce-session"))
      if (response.headers.get("woocommerce-session")) {
        // console.log("SESSION", response.url, response.headers.get("woocommerce-session"))
        useAuth.setState({
          session: {
            sessionToken: response.headers.get("woocommerce-session")!,
          },
        })
      }
    },
  })

  // Update the custom query key when the auth state changes
  // This will force all queries to revalidate! So if a user logs in, they'll see personalised data immediately
  useAuth.subscribe((state) => {
    const key = getQueryKey()
    if (apiConfig.customQueryKey !== key) {
      apiConfig.set({
        customQueryKey: getQueryKey(),
      })
    }
  })

  async function refresh(refreshToken: string) {
    const authState = useAuth.getState()
    const result = await useRefreshAuthToken.mutate({
      refreshToken: refreshToken,
    })
    if (result.refreshJwtAuthToken?.authToken) {
      // console.log("Just refreshed")
      useAuth.setState({
        user: {
          ...authState.user!,
          authToken: result.refreshJwtAuthToken.authToken,
          refreshTimeout: Date.now() + DEFAULT_AUTH_TOKEN_TIMEOUT,
        },
        authPromise: null,
      })
    }
  }

  // Function to check the refresh token, and refresh it if needed
  async function checkRefresh() {
    const authState = useAuth.getState()
    // console.log("Expires in minutes: ", (authState?.user?.refreshTimeout! - Date.now()) / 1000 / 60)
    if (authState.user && authState.user.refreshToken && (authState.user.refreshTimeout! || 0) < Date.now()) {
      await refresh(authState.user.refreshToken)
    }
  }

  // Schedule a check every minute
  setInterval(checkRefresh, 60000)
}

if (env.client && !env.admin) {
  init()
}
