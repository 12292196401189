import { Spinner } from "@components/atoms/Spinner"
import { Link } from "eddev/routing"
import { ComponentProps, ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { tv } from "tailwind-variants"

type ButtonType = "button" | typeof Link
export type ButtonVariantType = "charcoal" | "light" | "lightLink" | "glass" | "outline" | "reversed"
export type ButtonSizeType = "default" | "square" | "lg" | "lgSquare" | "chip"

type ButtonProps = ComponentProps<ButtonType> & {
  as?: any
  href?: string | null
  target?: string | null
  loading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  active?: boolean
  size?: ButtonSizeType
  square?: boolean
  variant?: ButtonVariantType
}

const buttonVariants = tv({
  slots: {
    base: [
      "inline-flex items-center relative justify-center cursor-pointer rounded-full antialiased transition-colors duration-200",
      "shadow-transparent",
      "active:transition-none data-[active]:transition-none",
      "disabled:cursor-default",
    ],
    icon: [""],
    label: ["inline-flex flex-none"],
    spinner: ["absolute inset-0 flex items-center justify-center opacity-[1!important]"],
  },
  variants: {
    variant: {
      active: "bg-black border-black text-white shadow-transparent active:bg-grey-mid disabled:bg-grey-dark disabled:text-grey-mid",
      charcoal: "bg-black shadow-transparent border-black text-white hover:bg-grey-dark hover:text-white active:bg-grey-mid disabled:bg-grey-dark disabled:text-grey-light/40",
      light: "bg-grey-light border-grey-light text-black hover:bg-[#DAD9D7] active:bg-[#CECBC9] disabled:bg-grey-light disabled:text-grey-mid/40",
      lightLink: "bg-grey-light border-grey-light text-black hover:bg-[#DAD9D7] link-active:bg-black link-active:text-white disabled:bg-grey-light disabled:text-grey-mid/40",
      glass: "bg-glass text-black hover:bg-grey-light active:bg-[#DAD9D7] disabled:bg-grey-light disabled:text-grey-mid/40",
      outline: "shadow-black border-black bg-glass text-black hover:bg-grey-light active:bg-[#DAD9D7] disabled:bg-glass disabled:text-grey-mid/40 disabled:shadow-grey-light",
      reversed: "shadow-glass border-glass bg-black text-white hover:bg-grey-dark hover:text-white active:bg-grey-mid disabled:bg-grey-dark disabled:text-grey-light/40",
    },
    size: {
      default: {
        base: "type-ui-m-light h-button px-[0.2em] shadow-[0_0_0_1px_inset]",
        label: "first-of-type:ml-[0.7em] last-of-type:mr-[0.7em]",
        icon: "mx-[0.2em] size-[1.3em] el-svg:size-full",
      },
      square: {
        base: "type-ui-m-light h-button w-button px-[0.2em] shadow-[0_0_0_1px_inset]",
        label: "",
        icon: "mx-[0.2em] size-[1.3em] el-svg:size-full",
      },
      lg: {
        base: "type-ui-l-light h-buttonLg px-[0.2em] shadow-[0_0_0_1px_inset]",
        label: "first-of-type:ml-[1em] last-of-type:mr-[1em]",
        icon: "mx-[0.4em] size-[1.3em] el-svg:size-full",
      },
      lgSquare: {
        base: "type-ui-l-light size-buttonLg px-[0.2em] shadow-[0_0_0_1px_inset] items-center justify-center",
        label: "",
        icon: "size-[1.4em] el-svg:size-full",
      },
      chip: {
        base: "type-ui-s-roman-caps h-buttonChip px-[0.2em] shadow-[0_0_0_0.5px_inset]",
        label: "first-of-type:ml-[0.6em] last-of-type:mr-[0.6em]",
        icon: "mx-[0.2em] size-[1.3em] el-svg:size-full",
      },
    },
    loading: {
      true: {
        base: "cursor-wait pointer-events-none *:opacity-0 *:data-[part=spinner]:opacity-1",
      },
    },
  },
  defaultVariants: {
    size: "default",
    variant: "charcoal",
  },
})

export function Button(props: ButtonProps) {
  const { variant, loading, className, size, active, leftIcon, rightIcon, ...passedProps } = props
  const Comp = props.as ?? (props.href ? Link : ("button" as any))

  const styles = buttonVariants({ variant: active ? "active" : variant, size, loading, className })

  return (
    <Comp {...passedProps} className={twMerge(styles.base(), props.className)} type={props.type ?? "button"}>
      {leftIcon && <span className={styles.icon()}>{leftIcon}</span>}

      {!!props.children && (
        <span className={styles.label()} data-part="label">
          {props.children}
        </span>
      )}

      {rightIcon && <span className={styles.icon()}>{rightIcon}</span>}

      {!!loading && (
        <em className={styles.spinner()} data-part="spinner">
          <Spinner />
        </em>
      )}
    </Comp>
  )
}

export function ToolbarButton(props: ButtonProps) {
  return <Button {...props} size="lgSquare" leftIcon={props.children} children={null} />
}

export function ChipButton(props: ButtonProps) {
  return (
    <Button {...props} size="chip">
      {props.children}
    </Button>
  )
}

export function Chip(props: ButtonProps) {
  return <Button {...props} variant={props.variant ?? "outline"} size="chip" className={props.href ? "" : "!pointer-events-none"} />
}

export function SocialButton(props: ButtonProps) {
  const types: { [key: string]: string } = {
    instagram: "Instagram",
    linkedin: "LinkedIn",
    x: "X",
    website: "Website",
  }
  return (
    <Button {...props} variant="outline" target="_blank" rel="noreferrer noopener">
      {typeof props.children === "string" && !!types[props.children] ? types[props.children] : props.children}
    </Button>
  )
}
