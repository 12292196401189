import { blockManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
import core from "../../../blocks/_core";

const manifest = {
  "acf/accounts-signup-wizard": dynamic(() => import("../../../blocks/accounts/signup-wizard.tsx")),
  "acf/article-article-header": dynamic(() => import("../../../blocks/article/article-header.tsx")),
  "acf/article-article-hero": dynamic(() => import("../../../blocks/article/article-hero.tsx")),
  "acf/article-article-siderail": dynamic(() => import("../../../blocks/article/article-siderail.tsx")),
  "acf/article-hall-of-fame-header": dynamic(() => import("../../../blocks/article/hall-of-fame-header.tsx")),
  "acf/article-video-embed": dynamic(() => import("../../../blocks/article/video-embed.tsx")),
  "acf/awards-awards-header": dynamic(() => import("../../../blocks/awards/awards-header.tsx")),
  "acf/content-accordion-list": dynamic(() => import("../../../blocks/content/accordion-list.tsx")),
  "acf/content-accordion-list-item": dynamic(() => import("../../../blocks/content/accordion-list-item.tsx")),
  "acf/content-button": dynamic(() => import("../../../blocks/content/button.tsx")),
  "acf/content-contact-form": dynamic(() => import("../../../blocks/content/contact-form.tsx")),
  "acf/content-divider": dynamic(() => import("../../../blocks/content/divider.tsx")),
  "acf/content-file-item": dynamic(() => import("../../../blocks/content/file-item.tsx")),
  "acf/content-file-items": dynamic(() => import("../../../blocks/content/file-items.tsx")),
  "acf/content-grid-rows": dynamic(() => import("../../../blocks/content/grid-rows.tsx")),
  "acf/content-grid-rows-item": dynamic(() => import("../../../blocks/content/grid-rows-item.tsx")),
  "acf/content-image": dynamic(() => import("../../../blocks/content/image.tsx")),
  "acf/content-image-scaleable": dynamic(() => import("../../../blocks/content/image-scaleable.tsx")),
  "acf/content-latest-posts": dynamic(() => import("../../../blocks/content/latest-posts.tsx")),
  "acf/content-membership-list": dynamic(() => import("../../../blocks/content/membership-list.tsx")),
  "acf/content-membership-list-item": dynamic(() => import("../../../blocks/content/membership-list-item.tsx")),
  "acf/content-numbered-rows": dynamic(() => import("../../../blocks/content/numbered-rows.tsx")),
  "acf/content-numbered-rows-item": dynamic(() => import("../../../blocks/content/numbered-rows-item.tsx")),
  "acf/content-numbered-rows-sub": dynamic(() => import("../../../blocks/content/numbered-rows-sub.tsx")),
  "acf/content-numbered-rows-sub-item": dynamic(() => import("../../../blocks/content/numbered-rows-sub-item.tsx")),
  "acf/content-partners": dynamic(() => import("../../../blocks/content/partners.tsx")),
  "acf/content-partners-item": dynamic(() => import("../../../blocks/content/partners-item.tsx")),
  "acf/content-partners-texts": dynamic(() => import("../../../blocks/content/partners-texts.tsx")),
  "acf/content-quote": dynamic(() => import("../../../blocks/content/quote.tsx")),
  "acf/content-role-rows": dynamic(() => import("../../../blocks/content/role-rows.tsx")),
  "acf/content-role-rows-item": dynamic(() => import("../../../blocks/content/role-rows-item.tsx")),
  "acf/content-spacer": dynamic(() => import("../../../blocks/content/spacer.tsx")),
  "acf/cta-cta-card": dynamic(() => import("../../../blocks/cta/cta-card.tsx")),
  "acf/events-event-editor-details": dynamic(() => import("../../../blocks/events/event-editor-details.tsx")),
  "acf/events-event-editor-header": dynamic(() => import("../../../blocks/events/event-editor-header.tsx")),
  "acf/events-event-editor-image": dynamic(() => import("../../../blocks/events/event-editor-image.tsx")),
  "acf/faq-faq-category": dynamic(() => import("../../../blocks/faq/faq-category.tsx")),
  "acf/faq-faq-display": dynamic(() => import("../../../blocks/faq/faq-display.tsx")),
  "acf/faq-faq-group": dynamic(() => import("../../../blocks/faq/faq-group.tsx")),
  "acf/faq-faq-item": dynamic(() => import("../../../blocks/faq/faq-item.tsx")),
  "acf/feeds-upcoming-events": dynamic(() => import("../../../blocks/feeds/upcoming-events.tsx")),
  "acf/layout-column": dynamic(() => import("../../../blocks/layout/column.tsx")),
  "acf/layout-columns": dynamic(() => import("../../../blocks/layout/columns.tsx")),
  "acf/media-carousel": dynamic(() => import("../../../blocks/media/carousel.tsx")),
  "acf/page-page-header": dynamic(() => import("../../../blocks/page/page-header.tsx")),
  "acf/resources-featured-resources": dynamic(() => import("../../../blocks/resources/featured-resources.tsx")),
  "acf/resources-resource-header": dynamic(() => import("../../../blocks/resources/resource-header.tsx")),
  "acf/subpage-become-a-member": dynamic(() => import("../../../blocks/subpage/become-a-member.tsx")),
  ...core,
};

const info = {
  "acf/accounts-signup-wizard": { "slug": "accounts/signup-wizard", "flags": {} },
  "acf/article-article-header": {
    "slug": "article/article-header",
    "tags": [],
    "flags": { "fullWidth": true, "selfAnimates": true },
  },
  "acf/article-article-hero": {
    "slug": "article/article-hero",
    "tags": [],
    "flags": { "fullWidth": true, "selfAnimates": true },
  },
  "acf/article-article-siderail": { "slug": "article/article-siderail", "tags": ["root"], "flags": {} },
  "acf/article-hall-of-fame-header": {
    "slug": "article/hall-of-fame-header",
    "tags": [],
    "flags": { "fullWidth": true },
  },
  "acf/article-video-embed": { "slug": "article/video-embed", "tags": ["root"], "flags": { "fullWidth": true } },
  "acf/awards-awards-header": { "slug": "awards/awards-header", "tags": [], "flags": {} },
  "acf/content-accordion-list": { "slug": "content/accordion-list", "tags": ["root"], "flags": {} },
  "acf/content-accordion-list-item": { "slug": "content/accordion-list-item", "flags": {} },
  "acf/content-button": { "slug": "content/button", "tags": ["root"], "flags": {} },
  "acf/content-contact-form": { "slug": "content/contact-form", "tags": ["root"], "flags": {} },
  "acf/content-divider": { "slug": "content/divider", "tags": ["root"], "flags": {} },
  "acf/content-file-item": { "slug": "content/file-item", "flags": {} },
  "acf/content-file-items": { "slug": "content/file-items", "tags": ["root"], "flags": { "selfAnimates": true } },
  "acf/content-grid-rows": { "slug": "content/grid-rows", "tags": ["root"], "flags": {} },
  "acf/content-grid-rows-item": { "slug": "content/grid-rows-item", "flags": {} },
  "acf/content-image": { "slug": "content/image", "tags": ["root", "content"], "flags": {} },
  "acf/content-image-scaleable": { "slug": "content/image-scaleable", "tags": ["root"], "flags": {} },
  "acf/content-latest-posts": { "slug": "content/latest-posts", "tags": ["root"], "flags": {} },
  "acf/content-membership-list": { "slug": "content/membership-list", "tags": ["root"], "flags": {} },
  "acf/content-membership-list-item": { "slug": "content/membership-list-item", "flags": {} },
  "acf/content-numbered-rows": { "slug": "content/numbered-rows", "tags": ["root"], "flags": {} },
  "acf/content-numbered-rows-item": { "slug": "content/numbered-rows-item", "flags": {} },
  "acf/content-numbered-rows-sub": { "slug": "content/numbered-rows-sub", "flags": {} },
  "acf/content-numbered-rows-sub-item": { "slug": "content/numbered-rows-sub-item", "flags": {} },
  "acf/content-partners": { "slug": "content/partners", "tags": ["root"], "flags": {} },
  "acf/content-partners-item": { "slug": "content/partners-item", "flags": {} },
  "acf/content-partners-texts": { "slug": "content/partners-texts", "tags": ["root"], "flags": {} },
  "acf/content-quote": { "slug": "content/quote", "tags": ["root", "content"], "flags": {} },
  "acf/content-role-rows": { "slug": "content/role-rows", "tags": ["root"], "flags": {} },
  "acf/content-role-rows-item": { "slug": "content/role-rows-item", "flags": {} },
  "acf/content-spacer": { "slug": "content/spacer", "tags": ["root", "content"], "flags": {} },
  "acf/cta-cta-card": { "slug": "cta/cta-card", "tags": ["cta-card"], "flags": {} },
  "acf/events-event-editor-details": { "slug": "events/event-editor-details", "tags": ["root"], "flags": {} },
  "acf/events-event-editor-header": { "slug": "events/event-editor-header", "tags": [], "flags": {} },
  "acf/events-event-editor-image": { "slug": "events/event-editor-image", "tags": [], "flags": {} },
  "acf/faq-faq-category": { "slug": "faq/faq-category", "tags": ["root"], "flags": {} },
  "acf/faq-faq-display": { "slug": "faq/faq-display", "tags": ["root"], "flags": {} },
  "acf/faq-faq-group": { "slug": "faq/faq-group", "tags": ["root"], "flags": {} },
  "acf/faq-faq-item": { "slug": "faq/faq-item", "tags": [], "flags": {} },
  "acf/feeds-upcoming-events": { "slug": "feeds/upcoming-events", "tags": ["root"], "flags": {} },
  "acf/layout-column": { "slug": "layout/column", "flags": {} },
  "acf/layout-columns": { "slug": "layout/columns", "tags": ["root"], "flags": {} },
  "acf/media-carousel": { "slug": "media/carousel", "tags": ["root"], "flags": { "fullWidth": true, "solidBg": true } },
  "acf/page-page-header": { "slug": "page/page-header", "tags": ["root"], "flags": {} },
  "acf/resources-featured-resources": {
    "slug": "resources/featured-resources",
    "flags": { "fullWidth": true, "selfAnimates": true },
  },
  "acf/resources-resource-header": { "slug": "resources/resource-header", "tags": [], "flags": { "fullWidth": true } },
  "acf/subpage-become-a-member": { "slug": "subpage/become-a-member", "tags": ["root"], "flags": {} },
};

blockManifestReader.set(manifest, info);

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}
