import { Button } from "@components/controls/Button"
import { AlertContent } from "@components/layout/AlertContent"
import { getErrorMessage } from "@utils/errors"
import { AnimatePresence, motion } from "framer-motion"
import { memo, ReactElement, ReactNode, useEffect, useState } from "react"
import { tv } from "tailwind-variants"
import { proxy, useSnapshot, ref } from "valtio"

type AlertProps = {
  title: string
  message: ReactNode
  canCancel?: boolean
  cancelLabel?: string
  confirmLabel?: string
  className?: string
  asForm?: boolean
  allowButtons?: boolean
  onConfirm?: (keepOpen: () => void) => any
}

type AlertInstance = {
  id: number
  confirming?: boolean
  requestClose: () => void
} & AlertProps

let _alertId = 0
const store = proxy({
  stack: [] as AlertInstance[],
})

export function useAlertOverlay() {
  const [alert, setAlert] = useState<AlertInstance | null>(null)

  useEffect(() => {
    if (alert) {
      store.stack.push({
        ...alert,
        message: ref(<>{alert.message}</>),
      })
      return () => {
        store.stack = store.stack.filter((a) => a.id !== alert.id)
      }
    }
  }, [alert])

  return {
    open(props: AlertProps) {
      setAlert({ ...props, id: _alertId++, requestClose: () => setAlert(null) })
    },
    close() {
      setAlert(null)
    },
  }
}

export function displayErrorAlert(error: any, props?: Partial<AlertProps>) {
  const id = _alertId++
  store.stack.push({
    id: id,
    title: "An error occurred",
    message: ref(<>{getErrorMessage(error)}</>),
    canCancel: false,
    confirmLabel: "Dismiss",
    requestClose: () => {
      store.stack = store.stack.filter((a) => a.id !== id)
    },
    onConfirm: () => {
      store.stack = store.stack.filter((a) => a.id !== id)
    },
    ...props,
  })
}

export function displayAlert(props: AlertProps) {
  const id = _alertId++
  store.stack.push({
    id,
    ...props,
    message: ref(<>{props.message}</>),
    requestClose: () => {
      store.stack = store.stack.filter((a) => a.id !== id)
    },
  })
}

export const AlertManager = memo(() => {
  const alerts = useSnapshot(store).stack as AlertInstance[]

  const alert = alerts.length > 0 ? alerts[alerts.length - 1] : null
  const alertSource = store.stack.find((a) => a.id === alert?.id)!

  const El = alert?.asForm ? motion.form : motion.div

  const onConfirm = async () => {
    if (!alert) return
    let keepOpen = false
    const promise = alert?.onConfirm?.(() => {
      keepOpen = true
    })
    if (promise instanceof Promise) {
      alertSource.confirming = true
      promise
        .then((result) => {
          if (result === false) {
            alertSource.confirming = false
            return
          }
          if (!keepOpen) {
            alert.requestClose()
          }
        })
        .catch((err) => {
          alertSource.confirming = false
          displayErrorAlert(err)
        })
    } else if (promise === false) {
      alertSource.confirming = false
    } else {
      alert.requestClose()
    }
  }

  return (
    <AnimatePresence>
      {!!alert && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[1000] bg-glass/90 backdrop-blur-md"
          onClick={(e) => {
            if (e.currentTarget === e.target && alert.canCancel) {
              alertSource.requestClose()
            }
          }}
        >
          <AnimatePresence>
            <El
              key={alert.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              noValidate
              className="absolute inset-0 flex items-center justify-center overflow-auto overscroll-contain"
              onSubmit={(e) => {
                e.preventDefault()
                onConfirm()
              }}
            >
              <AlertContent
                title={alert.title}
                message={alert.message}
                className={alert.className}
                buttons={
                  <>
                    {alert.canCancel && (
                      <Button variant="outline" onClick={() => alert.requestClose()} disabled={alert.confirming} type="button">
                        {alert.cancelLabel || "Cancel"}
                      </Button>
                    )}
                    <Button
                      loading={alert.confirming}
                      onClick={() => {
                        if (!alert.asForm) {
                          onConfirm()
                        }
                      }}
                      type={alert.asForm ? "submit" : "button"}
                    >
                      {alert.confirmLabel || "Confirm"}
                    </Button>
                  </>
                }
              />
            </El>
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
})
