import { Button } from "@components/controls/Button"
import { useAuth } from "@features/accounts/hooks/useAuth"
import { useAccountMenu } from "@hooks/useAccountMenu"
import { useHeader } from "@hooks/useHeader"
import { useMenu } from "@hooks/useMenu"
import clsx from "clsx"
import { Link, useRoute, useRouter, useRouterEvents } from "eddev/routing"
import { AnimatePresence, Variants, motion } from "framer-motion"
import { Fragment } from "react/jsx-runtime"
import { MenuItemFieldsFragment } from "../../types.graphql"
import { AuthButtons } from "./AuthButtons"
import { twMerge } from "tailwind-merge"
import { Flipped } from "react-flip-toolkit"
import { BigSearchBox } from "@components/controls/SearchBox"
import { Footer } from "./Footer"
import { useEffect, useLayoutEffect, useRef } from "react"

export function MenuOverlay() {
  const header = useHeader()
  const menuContainer = useRef<HTMLDivElement>(null)

  if (env.client) {
    // Disable page scrolling when the menu is open
    useLayoutEffect(() => {
      const scroller = document.scrollingElement as HTMLElement
      if (header.mainMenuOpen) {
        scroller.style.overflow = "hidden"
        scroller.style.height = "100vh"
      } else {
        scroller.style.overflow = ""
        scroller.style.height = ""
      }
    }, [header.mainMenuOpen])
  }

  useRouterEvents((e) => {
    if (e.type === "navigate:will-change") {
      header.close()
    }
  })

  return (
    <div ref={menuContainer}>
      {/* Menu backing */}
      <motion.div
        className={clsx(
          "fixed z-[399] backdrop-blur-lg bg-glass/70 inset-0 opacity-0 transition-[opacity] duration-200",
          header.mainMenuOpen ? "opacity-100 pointer-events-auto delay-0" : "pointer-events-none delay-200",
        )}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            header.close()
          }
        }}
      />

      {/* Account menu */}
      <AnimatePresence>
        {header.accountMenuOpen && (
          <motion.div
            key="main"
            className={twMerge("fixed inset-0 top-headerHeight z-[406]", header.mainMenuOpen ? "pointer-events-none" : "")}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                header.close()
              }
            }}
            variants={{
              hidden: {
                transition: {
                  staggerChildren: 0.01,
                },
              },
              visible: {
                transition: {
                  staggerChildren: 0.04,
                },
              },
            }}
            initial="hidden"
            exit="hidden"
            animate="visible"
          >
            <AccountMenuContents />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main menu */}
      <AnimatePresence mode="wait">
        {!!header.mainMenuOpen && (
          <motion.div
            className="fixed inset-0 z-[405] pointer-events-none overflow-hidden overscroll-contain"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                header.close()
              }
            }}
            variants={{
              hidden: {
                transition: {
                  staggerChildren: 0.01,
                },
              },
              visible: {
                transition: {
                  staggerChildren: 0.01,
                  delayChildren: 0.2,
                },
              },
            }}
            initial="hidden"
            exit="hidden"
            animate="visible"
          >
            <MainMenuContents />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export function AccountMenuContents() {
  const menu = useAccountMenu()

  return (
    <ul className="stack-y-2 items-end w-grid mx-auto pointer-events-none">
      {menu.map((item, i) => (
        <motion.li key={i} variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}>
          <Button className="pointer-events-auto" variant={item.action ? "outline" : "light"} href={item.href} onClick={item.onClick}>
            {item.label}
          </Button>
        </motion.li>
      ))}
    </ul>
  )
}

const fadeVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
  },
} satisfies Variants

export function MainMenuContents() {
  const close = useHeader((h) => h.close)
  const router = useRouter()
  const route = useRoute()
  const auth = useAuth()
  const discoverMenu = useMenu("Discover")
  const corporateMenu = useMenu("Corporate")

  const loggedIn = auth.isLoggedIn()

  const accountWelcome = "Welcome to AGDA."
  const accountAction = "Care to join?"

  return (
    <nav
      className="absolute top-headerHeight bottom-0 md:bottom-8 w-full pointer-events-auto overflow-auto"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          close()
        }
      }}
    >
      <div className="w-grid mx-auto stack-y-5 justify-between md:justify-normal pointer-events-none [&_.events]:pointer-events-auto relative md:pt-[calc(min(8vw,_10vh))]">
        {/* Welcome/Join/Sign-in */}
        <div className="order-1 md:order-none stack-x-2 py-5 md:py-0 justify-between pb-7 md:mb-5">
          <div className="stack-y-0 items-start">
            <motion.div variants={fadeVariants} className="type-title-m-light md:type-title-xl-roman events">
              {accountWelcome}
            </motion.div>
            <div className="stack-x-4">
              <motion.div variants={fadeVariants} className="type-title-m-medium md:type-title-xl-medium events">
                {accountAction}
              </motion.div>
              <AuthButtons className="hidden md:flex events" />
            </div>
          </div>
          <AuthButtons className="flex md:hidden events" />
        </div>

        {/* <Flipped flipId="bigSearchBox"> */}
        <motion.div variants={fadeVariants} className="will-change-transform relative block md:hidden">
          <BigSearchBox
            onSearch={(term, submitted) => {
              if (submitted) {
                router.navigate("/search?q=" + encodeURIComponent(term))
              }
            }}
            placeholder="Search..."
          />
        </motion.div>
        {/* </Flipped> */}

        {/* Menu Items */}
        <div className="grid-auto col-span-12 pt-7">
          <ul className="col-span-12 md:col-span-4">
            <MenuItems items={discoverMenu?.menuItems?.nodes!} />
          </ul>
          <ul className="col-span-12 md:col-span-4">
            <MenuItems items={corporateMenu?.menuItems?.nodes!} />
          </ul>
        </div>
      </div>
    </nav>
  )
}

function MenuItems(props: { items: MenuItemFieldsFragment[] }) {
  return (
    <Fragment>
      {props.items.map((item, i) => (
        <motion.li key={i} className="type-title-l-medium pb-3 md:pb-0 md:type-title-xl-medium" variants={fadeVariants}>
          <Link href={item.url} target={item.target!} className="hover:text-grey-dark inline-flex events">
            {item.label}
          </Link>
        </motion.li>
      ))}
    </Fragment>
  )
}
