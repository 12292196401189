import { createUseInfiniteQuery, createUseMutation, createUseQuery } from "eddev/hooks";
import {
  UseAccountDetailsQuery,
  UseAddCouponMutation,
  UseAddCouponMutationVariables,
  UseAddressCountryDataQuery,
  UseAddToCartMutation,
  UseAddToCartMutationVariables,
  UseAdminAwardEntryMutationMutation,
  UseAdminAwardEntryMutationMutationVariables,
  UseAdminAwardEntryQuery,
  UseAdminAwardEntryQueryVariables,
  UseAdminJudgeYearsQuery,
  UseAwardShowcaseQuery,
  UseAwardShowcaseQueryVariables,
  UseBranchesQuery,
  UseCancelMyMembershipMutation,
  UseCancelMyMembershipMutationVariables,
  UseCartIntentQuery,
  UseCartQuery,
  UseChangePasswordMutation,
  UseChangePasswordMutationVariables,
  UseChangePaymentMethodMutation,
  UseChangePaymentMethodMutationVariables,
  UseCheckoutMutation,
  UseCheckoutMutationVariables,
  UseCreateNewPaymentMethodIntentMutation,
  UseCreateNewPaymentMethodIntentMutationVariables,
  UseCreateNewPaymentMethodMutation,
  UseCreateNewPaymentMethodMutationVariables,
  UseEmptyCartMutation,
  UseEmptyCartMutationVariables,
  UseEventTicketsQuery,
  UseEventTicketsQueryVariables,
  UseExpertiseQuery,
  UseFillCartMutation,
  UseFillCartMutationVariables,
  UseFinishResetPasswordMutation,
  UseFinishResetPasswordMutationVariables,
  UseInfiniteArticleSearchQuery,
  UseInfiniteArticleSearchQueryVariables,
  UseInfiniteAwardShowcaseQuery,
  UseInfiniteAwardShowcaseQueryVariables,
  UseInfiniteDesignerDirectoryQuery,
  UseInfiniteDesignerDirectoryQueryVariables,
  UseInfiniteEventSearchQuery,
  UseInfiniteEventSearchQueryVariables,
  UseJuryQuery,
  UseJuryQueryVariables,
  UseLoginMutation,
  UseLoginMutationVariables,
  UseMemberStatusQuery,
  UseMyBillingQuery,
  UseMyInfoMutationMutation,
  UseMyInfoMutationMutationVariables,
  UseMyInfoQuery,
  UseMyMembershipQuery,
  UseMyOrdersQuery,
  UseMyPaymentMethodsQuery,
  UseMyProfileQuery,
  UseMySubscriptionsQuery,
  UseNewsletterSubscribeMutation,
  UseNewsletterSubscribeMutationVariables,
  UseOrderQuery,
  UseOrderQueryVariables,
  UsePrepareSavedPaymentMethodMutation,
  UsePrepareSavedPaymentMethodMutationVariables,
  UseReactivateSubscriptionMutation,
  UseReactivateSubscriptionMutationVariables,
  UseRefreshAuthTokenMutation,
  UseRefreshAuthTokenMutationVariables,
  UseRegisterCustomerMutation,
  UseRegisterCustomerMutationVariables,
  UseRemoveItemsFromCartMutation,
  UseRemoveItemsFromCartMutationVariables,
  UseRemovePaymentMethodMutation,
  UseRemovePaymentMethodMutationVariables,
  UseResetPasswordMutation,
  UseResetPasswordMutationVariables,
  UseSessionTokenQuery,
  UseSetDefaultPaymentMethodMutation,
  UseSetDefaultPaymentMethodMutationVariables,
  UseStudioQuery,
  UseStudioQueryVariables,
  UseUpdateAccountMutation,
  UseUpdateAccountMutationVariables,
  UseUpdateCartItemQuantitiesMutation,
  UseUpdateCartItemQuantitiesMutationVariables,
  UseUpdateMyBillingMutation,
  UseUpdateMyBillingMutationVariables,
  UseUpdateProfileImagesMutation,
  UseUpdateProfileImagesMutationVariables,
  UseUpdateProfileMutation,
  UseUpdateProfileMutationVariables,
  UseValidatePasswordMutation,
  UseValidatePasswordMutationVariables,
  UseVerifyMemberNumberMutation,
  UseVerifyMemberNumberMutationVariables,
} from "../types.graphql";

/**
 * DO NOT EDIT
 * This file is automatically generated by eddev
 * Any changes will be overwritten
 */

export const useInfiniteArticleSearch = createUseInfiniteQuery<
  Exclude<Exclude<UseInfiniteArticleSearchQuery["articles"], undefined | null>["nodes"], undefined | null>,
  UseInfiniteArticleSearchQueryVariables
>({
  name: "articles/UseInfiniteArticleSearch",
  defaultLimit: 8,
  paths: {
    "nodesPath": ["articles", "nodes"],
    "pageInfoPath": ["articles", "pageInfo"],
    "endCursorPath": ["articles", "pageInfo", "endCursor"],
    "hasNextPagePath": ["articles", "pageInfo", "hasNextPage"],
  },
});

export const useAdminAwardEntry = createUseQuery<UseAdminAwardEntryQuery, UseAdminAwardEntryQueryVariables>({
  name: "awards/admin/UseAdminAwardEntry",
});

export const useAdminAwardEntryMutation = createUseMutation<
  UseAdminAwardEntryMutationMutation,
  UseAdminAwardEntryMutationMutationVariables
>({ name: "awards/admin/UseAdminAwardEntryMutation" });

export const useAdminJudgeYears = createUseQuery<UseAdminJudgeYearsQuery, undefined>({
  name: "awards/admin/UseAdminJudgeYears",
});

export const useAwardShowcase = createUseQuery<UseAwardShowcaseQuery, UseAwardShowcaseQueryVariables>({
  name: "awards/UseAwardShowcase",
});

export const useInfiniteAwardShowcase = createUseInfiniteQuery<
  Exclude<Exclude<UseInfiniteAwardShowcaseQuery["awardEntries"], undefined | null>["nodes"], undefined | null>,
  UseInfiniteAwardShowcaseQueryVariables
>({
  name: "awards/UseInfiniteAwardShowcase",
  defaultLimit: 8,
  paths: {
    "nodesPath": ["awardEntries", "nodes"],
    "pageInfoPath": ["awardEntries", "pageInfo"],
    "endCursorPath": ["awardEntries", "pageInfo", "endCursor"],
    "hasNextPagePath": ["awardEntries", "pageInfo", "hasNextPage"],
  },
});

export const useJury = createUseQuery<UseJuryQuery, UseJuryQueryVariables>({ name: "awards/UseJury" });

export const useStudio = createUseQuery<UseStudioQuery, UseStudioQueryVariables>({ name: "awards/UseStudio" });

export const useAddCoupon = createUseMutation<UseAddCouponMutation, UseAddCouponMutationVariables>({
  name: "cart/UseAddCoupon",
});

export const useAddToCart = createUseMutation<UseAddToCartMutation, UseAddToCartMutationVariables>({
  name: "cart/UseAddToCart",
});

export const useCart = createUseQuery<UseCartQuery, undefined>({ name: "cart/UseCart" });

export const useCartIntent = createUseQuery<UseCartIntentQuery, undefined>({ name: "cart/UseCartIntent" });

export const useCheckout = createUseMutation<UseCheckoutMutation, UseCheckoutMutationVariables>({
  name: "cart/UseCheckout",
});

export const useEmptyCart = createUseMutation<UseEmptyCartMutation, UseEmptyCartMutationVariables>({
  name: "cart/UseEmptyCart",
});

export const useFillCart = createUseMutation<UseFillCartMutation, UseFillCartMutationVariables>({
  name: "cart/UseFillCart",
});

export const useOrder = createUseQuery<UseOrderQuery, UseOrderQueryVariables>({ name: "cart/UseOrder" });

export const usePrepareSavedPaymentMethod = createUseMutation<
  UsePrepareSavedPaymentMethodMutation,
  UsePrepareSavedPaymentMethodMutationVariables
>({ name: "cart/UsePrepareSavedPaymentMethod" });

export const useRemoveItemsFromCart = createUseMutation<
  UseRemoveItemsFromCartMutation,
  UseRemoveItemsFromCartMutationVariables
>({ name: "cart/UseRemoveItemsFromCart" });

export const useUpdateCartItemQuantities = createUseMutation<
  UseUpdateCartItemQuantitiesMutation,
  UseUpdateCartItemQuantitiesMutationVariables
>({ name: "cart/UseUpdateCartItemQuantities" });

export const useEventTickets = createUseQuery<UseEventTicketsQuery, UseEventTicketsQueryVariables>({
  name: "events/UseEventTickets",
});

export const useInfiniteEventSearch = createUseInfiniteQuery<
  Exclude<Exclude<UseInfiniteEventSearchQuery["events"], undefined | null>["nodes"], undefined | null>,
  UseInfiniteEventSearchQueryVariables
>({
  name: "events/UseInfiniteEventSearch",
  defaultLimit: 4,
  paths: {
    "nodesPath": ["events", "nodes"],
    "pageInfoPath": ["events", "pageInfo"],
    "endCursorPath": ["events", "pageInfo", "endCursor"],
    "hasNextPagePath": ["events", "pageInfo", "hasNextPage"],
  },
});

export const useBranches = createUseQuery<UseBranchesQuery, undefined>({ name: "find-a-designer/UseBranches" });

export const useExpertise = createUseQuery<UseExpertiseQuery, undefined>({ name: "find-a-designer/UseExpertise" });

export const useInfiniteDesignerDirectory = createUseInfiniteQuery<
  Exclude<Exclude<UseInfiniteDesignerDirectoryQuery["designerDirectory"], undefined | null>["nodes"], undefined | null>,
  UseInfiniteDesignerDirectoryQueryVariables
>({
  name: "find-a-designer/UseInfiniteDesignerDirectory",
  defaultLimit: 16,
  paths: {
    "nodesPath": ["designerDirectory", "nodes"],
    "pageInfoPath": ["designerDirectory", "pageInfo"],
    "endCursorPath": ["designerDirectory", "pageInfo", "endCursor"],
    "hasNextPagePath": ["designerDirectory", "pageInfo", "hasNextPage"],
  },
});

export const useChangePaymentMethod = createUseMutation<
  UseChangePaymentMethodMutation,
  UseChangePaymentMethodMutationVariables
>({ name: "users/payment-methods/UseChangePaymentMethod" });

export const useCreateNewPaymentMethod = createUseMutation<
  UseCreateNewPaymentMethodMutation,
  UseCreateNewPaymentMethodMutationVariables
>({ name: "users/payment-methods/UseCreateNewPaymentMethod" });

export const useCreateNewPaymentMethodIntent = createUseMutation<
  UseCreateNewPaymentMethodIntentMutation,
  UseCreateNewPaymentMethodIntentMutationVariables
>({ name: "users/payment-methods/UseCreateNewPaymentMethodIntent" });

export const useMyPaymentMethods = createUseQuery<UseMyPaymentMethodsQuery, undefined>({
  name: "users/payment-methods/UseMyPaymentMethods",
});

export const useRemovePaymentMethod = createUseMutation<
  UseRemovePaymentMethodMutation,
  UseRemovePaymentMethodMutationVariables
>({ name: "users/payment-methods/UseRemovePaymentMethod" });

export const useSetDefaultPaymentMethod = createUseMutation<
  UseSetDefaultPaymentMethodMutation,
  UseSetDefaultPaymentMethodMutationVariables
>({ name: "users/payment-methods/UseSetDefaultPaymentMethod" });

export const useAccountDetails = createUseQuery<UseAccountDetailsQuery, undefined>({ name: "users/UseAccountDetails" });

export const useCancelMyMembership = createUseMutation<
  UseCancelMyMembershipMutation,
  UseCancelMyMembershipMutationVariables
>({ name: "users/UseCancelMyMembership" });

export const useChangePassword = createUseMutation<UseChangePasswordMutation, UseChangePasswordMutationVariables>({
  name: "users/UseChangePassword",
});

export const useFinishResetPassword = createUseMutation<
  UseFinishResetPasswordMutation,
  UseFinishResetPasswordMutationVariables
>({ name: "users/UseFinishResetPassword" });

export const useLogin = createUseMutation<UseLoginMutation, UseLoginMutationVariables>({ name: "users/UseLogin" });

export const useMemberStatus = createUseQuery<UseMemberStatusQuery, undefined>({ name: "users/UseMemberStatus" });

export const useMyBilling = createUseQuery<UseMyBillingQuery, undefined>({ name: "users/UseMyBilling" });

export const useMyInfo = createUseQuery<UseMyInfoQuery, undefined>({ name: "users/UseMyInfo" });

export const useMyInfoMutation = createUseMutation<UseMyInfoMutationMutation, UseMyInfoMutationMutationVariables>({
  name: "users/UseMyInfoMutation",
});

export const useMyMembership = createUseQuery<UseMyMembershipQuery, undefined>({ name: "users/UseMyMembership" });

export const useMyOrders = createUseQuery<UseMyOrdersQuery, undefined>({ name: "users/UseMyOrders" });

export const useMyProfile = createUseQuery<UseMyProfileQuery, undefined>({ name: "users/UseMyProfile" });

export const useMySubscriptions = createUseQuery<UseMySubscriptionsQuery, undefined>({
  name: "users/UseMySubscriptions",
});

export const useReactivateSubscription = createUseMutation<
  UseReactivateSubscriptionMutation,
  UseReactivateSubscriptionMutationVariables
>({ name: "users/UseReactivateSubscription" });

export const useRefreshAuthToken = createUseMutation<UseRefreshAuthTokenMutation, UseRefreshAuthTokenMutationVariables>(
  { name: "users/UseRefreshAuthToken" },
);

export const useRegisterCustomer = createUseMutation<UseRegisterCustomerMutation, UseRegisterCustomerMutationVariables>(
  { name: "users/UseRegisterCustomer" },
);

export const useResetPassword = createUseMutation<UseResetPasswordMutation, UseResetPasswordMutationVariables>({
  name: "users/UseResetPassword",
});

export const useSessionToken = createUseQuery<UseSessionTokenQuery, undefined>({ name: "users/UseSessionToken" });

export const useUpdateAccount = createUseMutation<UseUpdateAccountMutation, UseUpdateAccountMutationVariables>({
  name: "users/UseUpdateAccount",
});

export const useUpdateMyBilling = createUseMutation<UseUpdateMyBillingMutation, UseUpdateMyBillingMutationVariables>({
  name: "users/UseUpdateMyBilling",
});

export const useUpdateProfile = createUseMutation<UseUpdateProfileMutation, UseUpdateProfileMutationVariables>({
  name: "users/UseUpdateProfile",
});

export const useUpdateProfileImages = createUseMutation<
  UseUpdateProfileImagesMutation,
  UseUpdateProfileImagesMutationVariables
>({ name: "users/UseUpdateProfileImages" });

export const useValidatePassword = createUseMutation<UseValidatePasswordMutation, UseValidatePasswordMutationVariables>(
  { name: "users/UseValidatePassword" },
);

export const useVerifyMemberNumber = createUseMutation<
  UseVerifyMemberNumberMutation,
  UseVerifyMemberNumberMutationVariables
>({ name: "users/UseVerifyMemberNumber" });

export const useAddressCountryData = createUseQuery<UseAddressCountryDataQuery, undefined>({
  name: "util/UseAddressCountryData",
});

export const useNewsletterSubscribe = createUseMutation<
  UseNewsletterSubscribeMutation,
  UseNewsletterSubscribeMutationVariables
>({ name: "util/UseNewsletterSubscribe" });
