import { Button, Chip } from "@components/controls/Button"
import { IconCaretRight } from "@components/svg/Icons"
import { useDelayedValue, useReduceMemo } from "@hooks/state-hooks"
import { useTopNav } from "@hooks/useTopNav"
import { Link, useRoute } from "eddev/routing"
import { AnimatePresence, MotionProps, motion } from "framer-motion"
import { tv } from "tailwind-variants"

const fadeVariants: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  layout: true,
}

export function TopNav() {
  const route = useRoute()
  const topNav = useDelayedValue(useTopNav(), 100)

  let contents = null

  const animKey = useReduceMemo<string>(
    (prev) => {
      if ((topNav?.mode === "buttons" || topNav?.mode === "sublinks") && topNav.key) {
        return topNav.key
      }
      return prev ?? ""
    },
    [topNav],
  )

  if (topNav?.mode === "buttons") {
    contents = topNav.links.map((item, index) => (
      <motion.li key={item.href} {...fadeVariants} layoutDependency={topNav}>
        <Link preferBack href={item.href} className={navButton()}>
          {item.label}
        </Link>
      </motion.li>
    ))
  } else if (topNav?.mode === "sublinks") {
    contents = [
      <motion.li key={topNav.top.href} {...fadeVariants} layoutDependency={topNav}>
        <Link preferBack href={topNav.top.href} className={navButton()} data-active-parent>
          {topNav.top.label}
        </Link>
      </motion.li>,
      <motion.li key="arrow" {...fadeVariants} layoutDependency={topNav} className="mx-2">
        <IconCaretRight />
      </motion.li>,
      ...topNav.links.map((item, index) => (
        <motion.li key={"sub_" + item.href} {...fadeVariants} layoutDependency={topNav}>
          <Link preferBack href={item.href} className={navLink()}>
            {item.label}
          </Link>
        </motion.li>
      )),
    ]
  } else if (topNav?.mode === "title") {
    contents = (
      <motion.li key={topNav.href} {...fadeVariants} layoutDependency={topNav}>
        <Link preferBack href={topNav.href} className={navButton()} data-active>
          {topNav.title}
        </Link>
      </motion.li>
    )
  }

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.ul
          key={animKey}
          className="hidden md:stack-x-3 relative z-[1] items-center"
          initial={{
            opacity: 0,
            y: -5,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
            y: 5,
          }}
          transition={{
            stiffness: 400,
          }}
        >
          <AnimatePresence initial={false}>{contents}</AnimatePresence>
        </motion.ul>
      </AnimatePresence>
      <AnimatePresence>
        {topNav?.mobileButton && (
          <motion.div className="md:hidden flex items-center justify-center" key={topNav?.mobileButton.href ?? topNav.mobileButton.label}>
            <Link href={topNav.mobileButton.href} className="type-title-xs-medium">
              {topNav.mobileButton.label}
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

const navButton = tv({
  base: [
    "type-ui-m-light px-[0.9em] h-button rounded-full inline-flex leading-none items-center justify-center relative",
    "hover:bg-grey-light/50 transition-colors duration-300",
    "[ul:not(:has([data-active]))_&]:link-child-active:bg-grey-light link-active:bg-grey-light data-[active-parent]:!bg-grey-light",
  ],
})

const navLink = tv({
  base: [
    "type-ui-s-roman-caps mx-2 h-button rounded-full inline-flex leading-none items-center justify-center underline-offset-[0.2em] relative",
    "underline hover:decoration-black/20 decoration-black/0 transition-colors duration-300",
    "[ul:not(:has(a[data-active]))_&]:link-child-active:decoration-black/30 link-active:decoration-black/30",
  ],
})
