import { create } from "zustand"

type MainMenuStore = {
  mainMenuOpen: boolean
  accountMenuOpen: boolean
  openMainMenu: () => void
  openUserMenu: () => void
  close: () => void
  closeAccountMenu: () => void
}

export const useHeader = create<MainMenuStore>((set, get) => ({
  mainMenuOpen: false,
  accountMenuOpen: false,
  openMainMenu: () => set({ mainMenuOpen: true, accountMenuOpen: false }),
  openUserMenu: () => set({ accountMenuOpen: true }),
  close: () => set({ mainMenuOpen: false, accountMenuOpen: false }),
  closeAccountMenu: () => set({ accountMenuOpen: false }),
}))
