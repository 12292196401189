import { ComponentProps, useEffect, useRef, useState } from "react"
import { TextInput } from "./TextInput"
import { twMerge } from "tailwind-merge"
import { IconSearch } from "@components/svg/Icons"
import { useDebounce } from "react-use"

type Props = Omit<ComponentProps<typeof TextInput>, "value" | "onChange"> & {
  onSearch(term: string, submitted?: boolean): void
}

export function SearchBox({ onSearch, ...props }: Props) {
  const [value, setValue] = useState((props.defaultValue as string) ?? "")
  const debounce = useRef<any>(0)

  const handle = (value: string, immediate?: boolean) => {
    clearTimeout(debounce.current)
    setValue(value)
    debounce.current = setTimeout(
      () => {
        onSearch(value, immediate)
      },
      immediate ? 0 : 500,
    )
  }

  useEffect(() => {
    if (value === "" && props.defaultValue) {
      setValue(props.defaultValue as string)
    }
  }, [props.defaultValue])

  return (
    <form
      className="flex items-center relative"
      onSubmit={(e) => {
        e.preventDefault()
        handle(value, true)
      }}
    >
      <TextInput
        {...props}
        value={value}
        onInput={(e) => {
          handle(e.currentTarget.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handle(e.currentTarget.value, true)
            e.preventDefault()
          }
        }}
        className={twMerge("bg-transparent flex-1", props.className)}
      />
      <div className="absolute right-0 top-0 bottom-0 flex items-center">
        <IconSearch />
      </div>
    </form>
  )
}

export function BigSearchBox({ onSearch, onSubmit, ...props }: Props) {
  const [value, setValue] = useState("")
  const debounce = useRef<any>(0)
  const handle = (value: string, immediate?: boolean) => {
    clearTimeout(debounce.current)
    debounce.current = setTimeout(
      () => {
        onSearch(value, immediate)
      },
      immediate ? 0 : 500,
    )
  }

  return (
    <div className="border-b pb-2 type-title-s-medium md:type-title-l-medium will-change-transform pointer-events-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handle(value, true)
        }}
        className="flex items-center"
      >
        <IconSearch className="translate-x-[-25%] text-grey-dark size-[1.6em]" />
        <input
          {...props}
          onInput={(e) => {
            handle(e.currentTarget.value)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handle(e.currentTarget.value, true)
              e.preventDefault()
            }
          }}
          className={twMerge("bg-transparent flex-1 outline-none placeholder:text-grey antialiased -ml-2", props.className)}
        />
      </form>
    </div>
  )
}
