import { motion } from "framer-motion"
import { PropsWithChildren, ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { tv } from "tailwind-variants"

type Props = {
  icon?: ReactNode
  title?: ReactNode
  message?: ReactNode
  buttons?: ReactNode
  className?: string
}

export const alertStyles = tv({
  slots: {
    alert: "p-6 max-w-[600px] w-full md:w-[70vw]",
    title: "type-title-l-medium border-b border-black pb-4 mb-4 flex items-center el-svg:size-[1.2em] gap-1",
    message: "type-body-s-roman",
    buttons: "flex justify-start gap-2 mt-6",
    button: "type-ui-m-light",
  },
})

export function AlertContent(props: Props) {
  const styles = alertStyles()
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className={twMerge(styles.alert(), props.className)}
      transition={{ stiffness: 200 }}
    >
      <h3 className={styles.title()}>
        {props.icon}
        <div>{props.title}</div>
      </h3>
      <div className={styles.message()}>{props.message}</div>
      <div className={styles.buttons()}>{props.buttons}</div>
    </motion.div>
  )
}
